import { Logger } from './logger.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_URL, API_URL_ADDRESS } from '../app.component';
import { environment } from '../../environments/environment';
import { Street } from '../model/address-setup/street';
import { Country } from '../model/address-setup/country';
import { State } from '../model/address-setup/state';
import { City } from '../model/address-setup/city';
import { Suburb } from '../model/address-setup/suburbDto';
export interface Results {
  addresses: string[];
}

@Injectable({
  providedIn: 'root'
})
export class AddressService {


  constructor(
    private http: HttpClient) { }

  // googleAddressApi= 'https://maps.googleapis.com/maps/api/geocode/json?address=';
  //googleAddressApiKey= 'AIzaSyAcNGsJU3_xuCNXXzzp7uP25g5lBLquT14';

  // public getAddressFromKeyWord(key: string): Observable<any>{
  //  // Logger.log('Trying to call google api with pincode' + key);
  //   const URL = `${this.googleAddressApi}` + `${key}` + '&region=nz' + '&key=' + `${this.googleAddressApiKey}`;
  // //  Logger.log('URL for google api:' + URL);
  //   return this.http.get<any>(URL, { observe: 'response' } );
  // }

  // google places
  googleAddressApiKey = environment.googleKey;
  additional = '&inputtype=textquery&fields=photos%2Cformatted_address%2Cname%2Crating%2Copening_hours%2Cgeometry&key='
  googleAddressApi = 'https://maps.googleapis.com/maps/api/place/findplacefromtext/json?input=';

  // public getAddressFromKeyWord(key: string): Observable<any>{

  //    const URL = `${this.googleAddressApi}`+`${key}`+`${this.additional}`+`${this.googleAddressApiKey}`;

  //    Logger.log('URL'+ URL);
  //    return this.http.get<any>(URL, { observe: 'response' } );
  //  }

  public getAddressFromKeyWord(key: string): Observable<any> {

    const URL = `https://maps.googleapis.com/maps/api/place/findplacefromtext/json?input=Sam&inputtype=textquery&formatted_address%2Cname%2Crating%2Copening_hours%2Cgeometry&key=${this.googleAddressApiKey}&sessiontoken=1234567890`;

    Logger.log(URL);

    return this.http.get<any>(URL, { observe: 'response' });
  }

  createCountry(country: Country) {
    return this.http.post<Country>(API_URL_ADDRESS + '/api/countries', country, { observe: 'response' });
  }

  getCountryList() {
    return this.http.get<Country[]>(API_URL_ADDRESS + '/api/countries', { observe: 'response' });
  }

  getCountryListById(countryId: string) {
    return this.http.get<Country>(API_URL_ADDRESS + '/api/countries/' + countryId, { observe: 'response' });
  }

  deleteCountryById(countryId: string) {
    return this.http.delete<Country>(API_URL_ADDRESS + '/api/countries/' + countryId, { observe: 'response' });
  }

  // state

  createState(state: State) {
    return this.http.post<State>(API_URL_ADDRESS + '/api/region', state, { observe: 'response' });
  }

  getStateList() {
    return this.http.get<State[]>(API_URL_ADDRESS + '/api/region', { observe: 'response' });
  }

  geStateListById(stateId: string) {
    return this.http.get<State>(API_URL_ADDRESS + '/api/region/' + stateId, { observe: 'response' });
  }

  deleteStateById(stateId: string) {
    return this.http.delete<State>(API_URL_ADDRESS + '/api/region/' + stateId, { observe: 'response' });
  }

  // city

  createCity(city: City) {
    return this.http.post<City>(API_URL_ADDRESS + '/api/city', city, { observe: 'response' });
  }

  getCityList() {
    return this.http.get<City[]>(API_URL_ADDRESS + '/api/city', { observe: 'response' });
  }
  getWebsiteCityListByBusinessType(businessType) {
    if(businessType == ''){
      return this.http.get<any[]>(API_URL + '/api/website/allCities' , { observe: 'response' });

    } else {
      return this.http.get<any[]>(API_URL + '/api/website/allCities?businessType='+businessType , { observe: 'response' });

    }
  }
  getWebsiteSuburbByCities(city) {
    if(city == ''){
    return this.http.get<any[]>(API_URL + '/api/website/allSuburbByCities?city=' , { observe: 'response' });
    } else {
    return this.http.get<any[]>(API_URL + '/api/website/allSuburbByCities?city='+city , { observe: 'response' });

    }
  }
  getCityListByName(city){
    return this.http.get<any[]>(API_URL_ADDRESS + '/api/city/findByName?cityName=' + city, { observe: 'response' });
  }
  geCityListById(cityId: string) {
    return this.http.get<City>(API_URL_ADDRESS + '/api/city/' + cityId, { observe: 'response' });
  }

  deleteCityById(cityId: string) {
    return this.http.delete<State>(API_URL_ADDRESS + '/api/city/' + cityId, { observe: 'response' });
  }

  // suburb

  createSubrub(suburb: Suburb) {
    return this.http.post<Suburb>(API_URL_ADDRESS + '/api/suburb', suburb, { observe: 'response' });
  }

  getSuburbList() {
    return this.http.get<Suburb[]>(API_URL_ADDRESS + '/api/suburb', { observe: 'response' });
  }

  getSuburbListByCityName(cityName) {
    return this.http.get<Suburb[]>(API_URL_ADDRESS + '/api/suburb?cityName=' + cityName, { observe: 'response' });
  }


  geSuburbListById(id: string) {
    return this.http.get<Suburb>(API_URL_ADDRESS + '/api/suburb/' + id, { observe: 'response' });
  }

  deleteSuburbById(suburbId: string) {
    return this.http.delete<Suburb>(API_URL_ADDRESS + '/api/suburb/' + suburbId, { observe: 'response' });
  }

  // steet

  createStreet(steet: Street) {
    return this.http.post<Suburb>(API_URL_ADDRESS + '/api/street', steet, { observe: 'response' });
  }

  getSteetListByStreetName(streetName: string) {
    return this.http.get<Street[]>(API_URL_ADDRESS + '/api/street?streetName=' + streetName, { observe: 'response' });
  }

  getStreetListById(StreetId: string) {
    return this.http.get<Street>(API_URL_ADDRESS + '/api/street/' + StreetId, { observe: 'response' });
  }

  deleteSteetById(steetId: string) {
    return this.http.delete<Street>(API_URL_ADDRESS + '/api/street/' + steetId, { observe: 'response' });
  }
  SteetfileUpload(formData: any): Observable<any> {
    return this.http.post<any>(API_URL_ADDRESS + '/api/street/upload/file', formData, { observe: 'response' });
  }

}
