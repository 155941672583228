import { ClosedDays } from './closedDays';
import { OpenDays } from './openDays';
import { BusinessServiceTypes } from './businessServiceTypes';
import { DeliveryOption } from './deliveryOption';

export class BusinessServiceDtoList {
  id: number;
  closedDays: ClosedDays[];
  openDays: OpenDays[];
  checkInTime: string;
  checkOutTime: string;
  name: string;
active:true;
  businessLocationName: string;
  customerLocationName: string;
  canChangeBusinessAddress: boolean;
  provideBusinessAndCustomerAddress: boolean;

  businessTermLocation: string;
  businessTermResource: string;

  businessProductName: string;
  businessServiceName: string;
  bookingButtonLabelText : string;
  deliveryOptionList: DeliveryOption[];
  maxLeadTime: number;
  minLeadTime: number;
  stdPrepTime: number;
  
  description: string;
  policy: string;
  
  propertyId: number;
  businessServiceTypes: BusinessServiceTypes[];
  serviceCloseList: [
    {
      day: string;
    }
  ];
  serviceOpenList: [
    {
      breakFromTime: string;
      breakToTime: string;
      closingTime: string;
      day: string;
      openingTime: string;
    }
  ];
  taxDetails: [
    {
      country: string,
      name: string,
      percentage: number,
      state: string,
      taxAmount: number,
      taxSlabsList: [
        {
          maxAmount: number,
          minAmount: number,
          percentage: number
        }
      ],
      taxableAmount: number
    }
  ];
  printerName: string;
  constructor() {}
}
