import { Review } from './../model/review';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_URL } from '../app.component';

@Injectable({
  providedIn: 'root'
})
export class ReviewService {

constructor(private http: HttpClient) { }
getReviewFindByPropertyId(propertyId) {
  return this.http.get<Review[]>(API_URL + '/api/review/findByPropertyId/' +  propertyId, { observe: 'response' });
}
getReviewFindByPropertyIdAndReviewStatus(propertyId, reviewStatus) {
  return this.http.get<Review[]>(API_URL + '/api/review/findByPropertyId/' +  propertyId + '/reviewStatus/' + reviewStatus , { observe: 'response' });
}
getReviewFindByReviewStatus(reviewStatus) {
  return this.http.get<Review[]>(API_URL + '/api/review/findByReviewStatus/' + reviewStatus , { observe: 'response' });
}
}
