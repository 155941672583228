
export class Audit {

  auditType : string;
  bookingId: number;
  id: number;
  newValue: string;
  operatorNotes: string;
  orderId :number;
  previousValue: string;
  propertyId: number;
  role: string;
  roomId: number;
  updateType: string;
  updatedAt: string;
  updatedBy: string;
  reservationId: string;

  constructor() {}
}
