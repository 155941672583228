import { Slots } from './../model/slots';
import { Injectable } from '@angular/core';
import { SlotReservation } from '../model/slotReservation';
import { HttpClient } from '@angular/common/http';
import { Slot } from '../model/slot';
import { API_URL } from '../app.component';
import { ClosedDays } from '../model/closedDays';
import { SlotTiming } from '../model/SlotTiming';
import { Resource } from '../model/resource';
import { LocationModel } from '../model/location';

@Injectable({
  providedIn: 'root'
})
export class ReservationServiceService {

  constructor(private http: HttpClient) { }

  checkSlotAvailability(slot: SlotReservation) {
    return this.http.post<SlotReservation>(API_URL + '/api/businessServiceType/checkAvailability', slot, { observe: 'response' });
  }

  getSlotsByBusinessServiceType( businessServiceTypeId: string) {
    return this.http.get<Slot>(API_URL + '/api/website/' + businessServiceTypeId + '/slot', { observe: 'response' });
  }
  getSlotsByBusinessServiceTypeAndDate(slot: SlotReservation, businessServiceTypeId: string) {
    return this.http.get<Slots>(API_URL + '/api/website/' + businessServiceTypeId + '/slots?Date=' + slot.date, { observe: 'response' });
  }
  book(slotReservation: SlotReservation) {
    return this.http.post<SlotReservation>(API_URL + '/api/website/book', slotReservation, { observe: 'response' });
  }
  reservationBySlotTimingId(slotId) {
    return this.http.get<any>(API_URL + '/api/website/slotReservation/findBySlotTiming/' + slotId, { observe: 'response' });
  }

  // booking(bookingDto: Booking) {

  //   return this.http.post<Slot[]>(API_URL + '/api/website/booking', bookingDto, { observe: 'response' });
  // }

}
