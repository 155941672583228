import { ProductGroup } from 'src/app/model/productGroup';

import { productVariationDtoList } from './productVariation';
export class AddToCart {

  id: number;
  name: string;
  productCode: string;
  shortDescription: string;
  description: string;
  toppingProductGroupId:number;
  extraProductGroupId:number;
  extraProductGroupDto:any[];
  productDtoList:any[];
  toppingproductDtoList:any[];
  // extrasId:numbanyer;
  sellUnitPrice: number;
  quantityProduct : number;
  totalPrice : number;
  buyUnitPrice: number;
  unitsInStock: number;
  unitsInOrder: number;
  discountedPrice: number;
  productGroupId : number;
  supplierId : number;
  imageList: [
    {
      name: string;
      description: string;
      id: string;
      mainImage: boolean;
      url: string;
    }
  ];
  variationDtoList: productVariationDtoList[];

  constructor() {}
}
