import { Component, Inject, OnInit, OnDestroy  } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/common';
import { NgcCookieConsentService, NgcInitializeEvent, NgcNoCookieLawEvent, NgcStatusChangeEvent } from 'ngx-cookieconsent';
import { Subscription }   from 'rxjs/Subscription';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  private popupOpenSubscription: Subscription;
  private popupCloseSubscription: Subscription;
  private initializeSubscription: Subscription;
  private statusChangeSubscription: Subscription;
  private revokeChoiceSubscription: Subscription;
  private noCookieLawSubscription: Subscription;
  title = 'Bookone Local - Be Local & Buy Local';
  tagManagerId = environment.tagManagerId;
  public constructor(
    // private ccService: NgcCookieConsentService,
    @Inject(DOCUMENT) private doc: any
  ) { }
  ngOnInit(){
    this.setGTagManager();
    //  // subscribe to cookieconsent observables to react to main events
    //  this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
    //   () => {
    //     // you can use this.ccService.getConfig() to do stuff...
    //   });

    // this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
    //   () => {
    //     // you can use this.ccService.getConfig() to do stuff...
    //   });

    // this.initializeSubscription = this.ccService.initialize$.subscribe(
    //   (event: NgcInitializeEvent) => {
    //     // you can use this.ccService.getConfig() to do stuff...
    //   });

    // this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
    //   (event: NgcStatusChangeEvent) => {
    //     // you can use this.ccService.getConfig() to do stuff...
    //   });

    // this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
    //   () => {
    //     // you can use this.ccService.getConfig() to do stuff...
    //   });

    //   this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
    //   (event: NgcNoCookieLawEvent) => {
    //     // you can use this.ccService.getConfig() to do stuff...
    //   });
  }
    private setGTagManager() {
    const s = this.doc.createElement('script');
    s.type = 'text/javascript';
    s.innerHTML = '(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push'
      + '({\'gtm.start\':new Date().getTime(),event:\'gtm.js\'});var f=d.getElementsByTagName(s)'
      + '[0],j=d.createElement(s),dl=l!=\'dataLayer\'?\'&l=\'+l:\'\';j.async=true;j.src='
      + '\'https://www.googletagmanager.com/gtm.js?id=\'+i+dl;f.parentNode.insertBefore(j,f);})'
      + '(window,document,\'script\',\'dataLayer\',\'' + this.tagManagerId + '\');';
    const head = this.doc.getElementsByTagName('head')[0];
    head.appendChild(s);
  }
  ngOnDestroy() {
    // unsubscribe to cookieconsent observables to prevent memory leaks
    // this.popupOpenSubscription.unsubscribe();
    // this.popupCloseSubscription.unsubscribe();
    // this.initializeSubscription.unsubscribe();
    // this.statusChangeSubscription.unsubscribe();
    // this.revokeChoiceSubscription.unsubscribe();
    // this.noCookieLawSubscription.unsubscribe();
  }
}
export const PARENTS_ORGANIZATION_ID = environment.parentOrganisationId;
export const API_URL = environment.apiUrl;
export const API_URL_PROMOTION = environment.apiUrlPromotion;
export const API_URL_ADDRESS = environment.apiUrlAddress;
export const APP_ID = environment.appId;
export const dashboardUrl = environment.dashboardUrl;
export const customerAppUrl = environment.customerAppUrl;
export const businessAppUrl = environment.businessAppUrl;

export const ANDROID_APP_URL = environment.androidAppUrl;
export const IOS_APP_URL = environment.iosAppUrl;
export const SMS_NUMBER = '+1 956 903 2629';
export const AUDIT_ORDER_CREATE = "ORDER_CREATE";

