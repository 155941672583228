import { SlotTiming } from 'src/app/model/SlotTiming';
import { LocationList } from './locationList';

export class ResourceList {

  name: string;
  desc: string;
  imageUrl: string;
  availableTimings: SlotTiming[];
  bookedTimings: SlotTiming[];
  locationList: LocationList[];
  resourceName: string;

  constructor()
      { }
}
