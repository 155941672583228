<div id="footer" class="sticky-footer bg-primary pt-5" *ngIf="showHideFlag === false">
   <!-- Main -->
   <div class="container">
      <div class="row">
         <div class="col-md-4 col-sm-6">
            <a target="_blank" href="{{ propertyDetails.bookoneWebSiteUrl }}"></a>
            <img class="footer-logo" src="assets/images/logo-white.png" alt="" />
            <!-- <h2 style="color: white;">Book & Save</h2> -->
            <br />

            <div class="text-widget text-white">
               <span class="text-white">{{ propertyDetails.address }}</span>

               <br />
               Phone:
               <span class="text-white">
                  <!-- <a  href="tel:{{propertyDetails.mobile}}">{{propertyDetails.mobile}}</a>  -->
                  <a *ngIf="propertyDetails.mobile" class="text-white" href="tel:{{ propertyDetails.mobile }}">Customer
                     Care:{{ propertyDetails.mobile }}</a><a *ngIf="propertyDetails.landline" class="text-white"
                     href="tel:{{ propertyDetails.landline }}">, Landphone:{{ propertyDetails.landline }}</a>
                  <br />
                  <a *ngIf="propertyDetails.supportPhoneNumber" class="text-white"
                     href="tel:{{ propertyDetails.supportPhoneNumber }}">Support: {{ propertyDetails.supportPhoneNumber
                     }}</a>
               </span><br />
               E-Mail:
               <span class="text-white">
                  <br />
                  Customer Care:
                  <a *ngIf="propertyDetails.email" class="text-white"
                     href="mailto:{{ propertyDetails.email }}">{{ propertyDetails.email }}</a>
                  <br />
                  Support:
                  <a *ngIf="propertyDetails.supportEmailAddress" class="text-white"
                     href="mailto:{{ propertyDetails.supportEmailAddress }}">{{ propertyDetails.supportEmailAddress }}</a>
               </span><br />
            </div>
            <!-- <a href="tel:+0800265663" > <img class="footer-logo" src="assets/images/call-banner.png" alt=""  height="80px"></a> -->
            <div class="mt-4">
               <a *ngIf="propertyDetails.instagram" target="_blank" href="{{ propertyDetails.instagram }}"
                  class="btn btn-neutral btn-icon-only btn-round btn-lg" data-toggle="tooltip"
                  data-original-title="Follow us">
                  <i class="fa fa-instagram"></i>
               </a>
               <a *ngIf="propertyDetails.facebook" target="_blank" href="{{ propertyDetails.facebook }}"
                  class="btn btn-neutral btn-icon-only btn-round btn-lg" data-toggle="tooltip"
                  data-original-title="Like us">
                  <i class="fa fa-facebook-square"></i>
               </a>

               <a *ngIf="propertyDetails.twitter" target="_blank" href="{{ propertyDetails.twitter }}"
                  class="btn btn-neutral btn-icon-only btn-lg btn-round" data-toggle="tooltip"
                  data-original-title="Follow us">
                  <i class="fa fa-twitter"></i>
               </a>
               <!-- <a target="_blank" href="https://github.com/" class="btn btn-neutral btn-icon-only btn-round btn-lg" data-toggle="tooltip" data-original-title="Star on Github">
               <i class="fa fa-github"></i>
               </a> -->
            </div>
         </div>
         <div class="col-md-2 col-sm-6">
            <h4 style="color: white">COMPANY</h4>
            <ul class="footer-links">
               <!-- <li><a class="text-white" routerLink='/blog/list'>Blog</a></li> -->
               <li><a class="text-white" routerLink='/posts'>Blog</a></li>
               <li><a class="text-white" routerLink="/about">About</a></li>
               <li><a class="text-white" routerLink="/team">Team</a></li>
               <li><a class="text-white" routerLink="/contact">Contact</a></li>
            </ul>
         </div>
         <div class="col-md-2 col-sm-6">
            <h4 style="color: white">FOR YOU</h4>
            <ul class="footer-links">
               <li>
                  <a class="text-white" routerLink="/registration">List Your Business</a>
               </li>
               <!-- <li><a class="text-white" routerLink='/listing'>Services</a></li> -->
               <li><a class="text-white" routerLink="/pricing">Pricing</a></li>

               <li>
                  <a class="text-white" routerLink="/privacy">Privacy Policy</a>
               </li>
            </ul>
         </div>
         <div class="col-md-4 col-sm-6">
            <h4 style="color: white">Get The Mobile App</h4>
            <div class="row">
               <a target="_blank" href="{{ androidAppUrl }}"><img class="col-sm-12 mb-05 applogo1" width="120px"
                     src="assets/images/playdownload.png" alt="" /></a>

               <a target="_blank" href="{{ iosAppUrl }}"><img class="col-sm-12 mb-05 applogo2" width="120px"
                     src="assets/images/appdownload.png" alt="" /></a>
            </div>
         </div>
      </div>
      <!-- Copyright -->
      <div class="row">
         <div class="col-12">
            <div class="text-white">
               BookOne Local is a platform to search a business, book a service,
               order a product listed under your Business or Service offerings.
            </div>
         </div>
         <div class="col-md-12">
            <div class="copyrights text-white">
               Made with ❤ by
               <a href="https://www.credencesoft.co.nz/"><img class="sh-image-url" src="assets/images/cs-logo.png"
                     height="25px" alt="CredenceSoft-outline" /></a><br />
               Powered by BookOne © 2021 {{ propertyDetails.name }}. All Rights
               Reserved.
            </div>
         </div>
      </div>
   </div>
</div>
<div  *ngIf="showHideFlag === true">

</div>


