import { Product } from 'src/app/model/product';
import { Address } from 'src/app/model/address';
import { TaxDetails } from './TaxDetail/TaxDetails';
import { OrderLineDto } from './orderLineDto';
import { KOT } from './KOT';



export class OrderDto {
  advanceAmount: number;
  bookOneOrderId: string;
  bookOneReviewId: number;
  bookingId: number;
  totalCount: number;
  businessReservationNumber: string;
  businessServiceId: number;
  createdBy:string;
  businessServiceTypeId: number;
  counterName: string;
  counterNumber: string;
  couponCode: string;
  currency: string;
  customerId: number;
  customerName: string;
  deliveryChargeAmount: number;
  deliveryCode: string;
  deliveryMethod: string;
  discountAmount: number;
  discountPercentage: number;
  email: string;
  expenseId: number;
  externalOrderId: string;
  externalSite: string;
  firstName: string;
  id: number;
  invoiceId: number;
  invoiceNumber: string;
  lastName: string;
  locationName: string;
  mobile: string;
  modeOfPayment: string;
  netAmount: number;
  operatorName: string;
  orderLineDtoList: OrderLineDto[];
  orderPaymentStatus: string;
  orderStatus: string;
  orderedDate: string;
  orderedTime: string;
  paymentId: number;
  phone: string;
  productDtoList: Product[];
  propertyId: number;
  propertyLogoUrl: string;
  propertyName: string;
  refundAmount: number;
  requiredDate: string;
  requiredTime: string;
  resourceName: string;
  roomNo: string;
  serviceChargeAmount: number;
  serviceId: number;
  shipToAddress: Address;
  shipperId: number;
  specialNotes: string;
  subTotalAmount: number;
  taxAmount: number;
  taxDetails: TaxDetails[];
  totalOrderAmount: number;
  serviceChargeName: string;
  serviceChargePercentage: number;
  noOfPerson: number;
  message: string;
  kotDtoList: KOT[];
  orderSlot: string;
  orderCompleteTime: string;
  planName: string;
  paidAmount: number;
  outstandingAmount: number;
  kotPrintCount: number;

  afterTaxAmount: number;
  beforeTaxAmount: number;
  beforeTaxAmountAfterDiscount: number;
  bookingArrivalDate: string;
  bookingDepartureDate: string;
  orderInvoicePrintCount:number;
  complimentary:boolean;
  productDiscount:number;
  constructor() {}
}
