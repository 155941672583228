import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BusinessUser } from '../model/user';
import { API_URL } from '../app.component';
import { ProductGroup } from '../model/productGroup';
import { GoogleReview } from '../model/googleReview';

@Injectable({
  providedIn: 'root'
})

export class ListingService {

  constructor(private http: HttpClient) { }

  findProperties(applicationUser: BusinessUser) {
    return this.http.post<BusinessUser[]>(API_URL + '/api/website/findProperties', applicationUser, { observe: 'response' });
  }
  findProductsByBusinessServiceId(businessServiceId: number) {
    return this.http.get<ProductGroup[]>(API_URL + '/api/website/getProductList/' + businessServiceId, { observe: 'response' });
  }

  // Not Working
  findByPropertyNameAndSuburb(suburb: string, propertyName: string) {
    return this.http.get<BusinessUser>(API_URL + '/api/website/findByPropertyNameAndSuburb/' + suburb + '/' + propertyName, { observe: 'response' });
  }

  findPropertyBySEOName(seoFriendlyName: string) {
    return this.http.get<BusinessUser>(API_URL + '/api/website/findByPropertyBySEOFriendlyName/' + seoFriendlyName, { observe: 'response' });
  }

  findByPropertyId(id: number) {
    return this.http.get<BusinessUser>(API_URL + '/api/website/findByPropertyId/' + id, { observe: 'response' });
  }

  getBusinessBranch(id) {
    return this.http.get<any[]>(API_URL + '/api/website/' + id + '/branches', { observe: 'response' });
  }

  getAllReview(propertyId: number) {
    return this.http.get<GoogleReview[]>(API_URL + '/api/website/getGoogleReviews?PropertyId=' + propertyId, { observe: 'response' });
  }

  getPropertiesByLocality(locality: String) {
    return this.http.get<BusinessUser[]>(API_URL + '/api/website/getPropertiesByLocality/' + locality, { observe: 'response' });
  }
}
