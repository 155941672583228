<div class="main-wrapper " style="margin-top: 10px; margin-left: 10px;">
  <h3 style="text-align: center;">Order Details</h3>
  <button class="btn btn-sm btn-outline-primary mb-4" style="font-size: 16px;" (click)="backClicked()">
    <i style="font-size: 16px;" class="fa fa-arrow-left" aria-hidden="true"></i> <b>Go Back</b>
  </button> 
  <!--Title Bar -->
  <!-- <title-bar title="Order Checkout" subtitle=""></title-bar> -->
  <!-- Container -->

  <div class="content" >
    <div class="container">
      <div class="row">
        <div class="col-12">
       
          <div *ngIf="loader" class="progress">
            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100"
              aria-valuemin="0" aria-valuemax="100" style="width: 100%"></div>
          </div>
        </div>
    <!-- Sidebar -->
    <div class="col-lg-12 col-md-12">
      <div class="row">
        <div class="col-12 ">
          <h5 class="pl-4 pr-4" style="text-align: center;">Cart Details: ({{calculateQuantity()}})</h5>
          <table class="table table-bordered table-striped w-100 product-table">
            <thead class="custom-summary-th">
              <tr>
                <th>Details</th>
                <th>Quantity</th>
                <th>Types</th>
                <th>Unit Price</th>
                <!-- <th>Discount Price</th>
                <th>Discount (%)</th> -->
                <th>Amount</th>
                <th>Notes</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let product of addToCartList">
                <ng-container *ngIf="product!= null && product.variationDtoList == undefined">
                  <tr>
                    <td>
                      <div class="d-flex align-items-center">
                        <img *ngIf="product.imageList?.length" [src]="product.imageList[0].url" alt="" class="img-thumbnail" width="60" height="60">
                        <img *ngIf="!product.imageList?.length" src="/assets/images/fast-food.png" alt="" class="img-thumbnail" width="60" height="60">
                        <span class="ml-3">
                          {{ product.name }} 
                          <div class="badge badge-primary ml-2" *ngIf="product.discountedPrice !== null && product.discountedPrice !== 0">
                            {{ 100 - (100 /product.sellUnitPrice) * product.discountedPrice | number:'1.0-2' }}% Off
                          </div>
                        </span>
                      </div>
                    </td>
                    <td >{{ product.quantityProduct }}</td>
                    <td >Main Item</td>
                    <td >{{product.sellUnitPrice | currency: currency:'symbol':'1.2-2'}}</td>
                    <!-- <td >
                      <span *ngIf="product.discountedPrice !== null && product.discountedPrice !== 0">
                        {{product.discountedPrice | currency: currency:'symbol':'1.2-2' }}
                      </span>
                      <span *ngIf="product.discountedPrice == null || product.discountedPrice == 0">-</span>
                    </td>
                    <td >
                      <span *ngIf="product.discountedPrice !== null && product.discountedPrice !== 0">
                        {{ 100 - (100 /product.sellUnitPrice) * product.discountedPrice | number:'1.0-2' }}%
                      </span>
                      <span *ngIf="product.discountedPrice == null || product.discountedPrice == 0">0%</span>
                    </td> -->
                    <td >{{ product.totalPrice | currency: currency:'symbol':'1.2-2' }}</td>
                    <td> {{product.notes}}</td>
                  </tr>
                </ng-container>
                
                <ng-container *ngIf="product!= null && product.variationDtoList !=  undefined">
                  <ng-container *ngFor="let item of product.variationDtoList; let i=index">
                    <tr> <td >
                      <div class="d-flex align-items-center">
    
                      <img *ngIf="product.imageList?.length"  [src]="product.imageList[0].url" alt=""class="img-thumbnail" width="60" height="60">
                      <img  *ngIf="!product.imageList?.length" src="/assets/images/fast-food.png" alt="" class="img-thumbnail" width="60" height="60">
                      <span class="ml-3" >{{ product.name }}- {{ item.name }}<div class="badge badge-primary ml-2"
                        *ngIf="item.discountedPrice !== null && item.discountedPrice !== 0">{{ 100 - (100 /item.sellUnitPrice) *  item.discountedPrice | number:'1.0-2' }}% Off</div></span>
                      </div>
                     
                      </td>
                    <td > {{ item.quantityVariation }}</td>
                    <td >Main Item</td>
                    <td >{{item.sellUnitPrice   | currency: currency:'symbol':'1.2-2'}}</td>
                    <!-- <td ><span *ngIf="item.discountedPrice !== null && item.discountedPrice !== 0">{{item.discountedPrice  | currency: currency:'symbol':'1.2-2'}}</span><span *ngIf="item.discountedPrice == null || item.discountedPrice == 0">-</span></td> -->
                    <!-- <td  ><span *ngIf="item.discountedPrice !== null && item.discountedPrice !== 0">{{ 100 - (100 /item.sellUnitPrice) *  item.discountedPrice | number:'1.0-2' }}%</span><span *ngIf="item.discountedPrice == null || item.discountedPrice == 0">0%</span></td> -->
                    <td >{{ item.totalPrice | currency: currency:'symbol':'1.2-2' }}</td>
                  <td >{{item.notes}}</td>
                  </tr>
                  </ng-container>
                </ng-container>
               
                
              </ng-container>
            </tbody>
          </table>
          
          <div class="mobile-card-container">
            <!-- Main Items without Variations -->
            <ng-container *ngFor="let product of addToCartList">
              <ng-container *ngIf="product != null && product.variationDtoList == undefined">
                <div class="mobile-card">
                  <img *ngIf="product.imageList?.length" [src]="product.imageList[0].url" alt="">
                  <img *ngIf="!product.imageList?.length" src="/assets/images/fast-food.png" alt="">
                  
                  <div class="mobile-card-details">
                    <div class="mobile-card-title">{{ product.name }}
                      <span class="mobile-card-badge badge badge-primary"
                            *ngIf="product.discountedPrice !== null && product.discountedPrice !== 0">
                        {{ 100 - (100 / product.sellUnitPrice) * product.discountedPrice | number:'1.0-2' }}% Off
                      </span>
                    </div>
          
                    <div class="mobile-card-info">
                      <span>Quantity: {{ product.quantityProduct }}</span>
                      <span>Type: Main Item</span>
                    </div>
          
                    <div class="mobile-card-info">
                      <span>Unit Price: {{ product.sellUnitPrice | currency: currency:'symbol':'1.2-2' }}</span>
                      <!-- <span>Discount Price: 
                        <span *ngIf="product.discountedPrice !== null && product.discountedPrice !== 0">
                          {{ product.discountedPrice | currency: currency:'symbol':'1.2-2' }}
                        </span>
                        <span *ngIf="product.discountedPrice == null || product.discountedPrice == 0">{{ 0 | currency: currency:'symbol':'1.2-2' }}</span>
                      </span> -->
                      <span>Amount: {{ product.totalPrice | currency: currency:'symbol':'1.2-2' }}</span>
                    </div>
          
                    <div class="mobile-card-info">
                      <!-- <span>Discount %: 
                        <span *ngIf="product.discountedPrice !== null && product.discountedPrice !== 0">
                          {{ 100 - (100 / product.sellUnitPrice) * product.discountedPrice | number:'1.0-2' }}%
                        </span>
                        <span *ngIf="product.discountedPrice == null || product.discountedPrice == 0">0%</span>
                      </span> -->
                    <span>
                        Notes:
                        <span>{{product.notes}}</span>
                    </span>
                    </div>
                  </div>
                </div>
              </ng-container>
          
              <!-- Variations of Products -->
              <ng-container *ngIf="product.variationDtoList?.length">
                <div *ngFor="let variation of product.variationDtoList; let i=index" class="mobile-card">
                  <img *ngIf="product.imageList?.length" [src]="product.imageList[0].url" alt="">
                  <img *ngIf="!product.imageList?.length" src="/assets/images/fast-food.png" alt="">
          
                  <div class="mobile-card-details">
                    <div class="mobile-card-title">{{ product.name }} ({{ variation.name }})
                      <span class="mobile-card-badge badge badge-primary"
                            *ngIf="variation.discountedPrice !== null && variation.discountedPrice !== 0">
                        {{ 100 - (100 / variation.sellUnitPrice) * variation.discountedPrice | number:'1.0-2' }}% Off
                      </span>
                    </div>
          
                    <div class="mobile-card-info">
                      <span>Quantity: {{ variation.quantityVariation }}</span>
                      <span>Type: Variation</span>
                    </div>
          
                    <div class="mobile-card-info">
                      <span>Unit Price: {{ variation.sellUnitPrice | currency: currency:'symbol':'1.2-2' }}</span>
                      <span>Amount: {{ variation.totalPrice | currency: currency:'symbol':'1.2-2' }}</span>
                      <!-- <span>Discount Price: 
                        <span *ngIf="variation.discountedPrice !== null && variation.discountedPrice !== 0">
                          {{ variation.discountedPrice | currency: currency:'symbol':'1.2-2' }}
                        </span>
                        <span *ngIf="variation.discountedPrice == null || variation.discountedPrice == 0">{{ 0 | currency: currency:'symbol':'1.2-2' }}</span>
                      </span> -->
                    </div>
          
                    <div class="mobile-card-info">
                      <span>Notes: {{variation.notes}}</span>
                      <!-- <span>Discount %: 
                        <span *ngIf="variation.discountedPrice !== null && variation.discountedPrice !== 0">
                          {{ 100 - (100 / variation.sellUnitPrice) * variation.discountedPrice | number:'1.0-2' }}%
                        </span>
                        <span *ngIf="variation.discountedPrice == null || variation.discountedPrice == 0">0%</span>
                      </span> -->
                     
                    </div>
                  </div>
                </div>
              </ng-container>
          
              <!-- Additional Product Types (e.g., Add-ons or Side Items) -->
              <ng-container *ngIf="product.additionalItems?.length">
                <div *ngFor="let additionalItem of product.additionalItems" class="mobile-card">
                  <img *ngIf="additionalItem.imageList?.length" [src]="additionalItem.imageList[0].url" alt="">
                  <img *ngIf="!additionalItem.imageList?.length" src="/assets/images/fast-food.png" alt="">
          
                  <div class="mobile-card-details">
                    <div class="mobile-card-title">{{ additionalItem.name }}
                      <span class="mobile-card-badge badge badge-primary"
                            *ngIf="additionalItem.discountedPrice !== null && additionalItem.discountedPrice !== 0">
                        {{ 100 - (100 / additionalItem.sellUnitPrice) * additionalItem.discountedPrice | number:'1.0-2' }}% Off
                      </span>
                    </div>
          
                    <div class="mobile-card-info">
                      <span>Quantity: {{ additionalItem.quantity }}</span>
                      <span>Type: Add-on</span>
                    </div>
          
                    <div class="mobile-card-info">
                      <span>Unit Price: {{ additionalItem.sellUnitPrice | currency: currency:'symbol':'1.2-2' }}</span>
                      <span>Amount: {{ additionalItem.totalPrice | currency: currency:'symbol':'1.2-2' }}</span>
                      <!-- <span>Discount Price: 
                        <span *ngIf="additionalItem.discountedPrice !== null && additionalItem.discountedPrice !== 0">
                          {{ additionalItem.discountedPrice | currency: currency:'symbol':'1.2-2' }}
                        </span>
                        <span *ngIf="additionalItem.discountedPrice == null || additionalItem.discountedPrice == 0">{{ 0 | currency: currency:'symbol':'1.2-2' }}</span>
                      </span> -->
                    </div>
          
                    <div class="mobile-card-info">
                      <!-- <span>Discount %: 
                        <span *ngIf="additionalItem.discountedPrice !== null && additionalItem.discountedPrice !== 0">
                          {{ 100 - (100 / additionalItem.sellUnitPrice) * additionalItem.discountedPrice | number:'1.0-2' }}%
                        </span>
                        <span *ngIf="additionalItem.discountedPrice == null || additionalItem.discountedPrice == 0">0%</span>
                      </span> -->
                      
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
          
          
          

      </div>
        </div>
      
        <h5 class="pl-4 pr-4" style="text-align: center; margin-top: 40px;"><i class="fa text-primary fa-calendar-check-o"></i> Order Summary</h5>

        <!-- Table for desktop and tablet view -->
        <table class="custom-order-summary-table">
          <tr class="custom-summary-tr">
            <th class="custom-summary-th">Description</th>
            <th class="custom-summary-th">Details</th>
          </tr>
          <tr class="custom-summary-tr">
            <td class="custom-summary-td"><b>Order Date:</b></td>
            <td class="custom-summary-td">{{ this.orderDateUI }}</td>
          </tr>
          <!-- <tr class="custom-summary-tr">
            <td class="custom-summary-td"><b>Prepare Time:</b></td>
            <td class="custom-summary-td">
              <span *ngIf="this.prepareDay != 0">{{this.prepareDay}} Days </span>
              <span *ngIf="this.prepareHour != 0">{{ this.prepareHour }} Hours </span>
              <span *ngIf="this.prepareMinute != 0">{{this.prepareMinute}} Minutes </span>
            </td>
          </tr>
          <tr class="custom-summary-tr">
            <td class="custom-summary-td"><b>Delivery Duration:</b></td>
            <td class="custom-summary-td">
              <span *ngIf="this.leadDayTotal != 0">{{this.leadDayTotal}} Days </span>
              <span *ngIf="this.leadHourTotal != 0">{{ this.leadHourTotal }} Hours </span>
              <span *ngIf="this.leadMinTotal != 0">{{this.leadMinTotal}} Minutes </span> to 
              <span *ngIf="this.leadMaxDayTotal != 0">{{ this.leadMaxDayTotal }} Days </span>
              <span *ngIf="this.leadMaxHourTotal != 0">{{this.leadMaxHourTotal }} Hours</span> 
              <span *ngIf="this.leadMaxMinTotal != 0">{{this.leadMaxMinTotal}} Minutes </span>
            </td>
          </tr> -->
          <tr class="custom-summary-tr">
            <td class="custom-summary-td"><b>Pickup/Delivery Date & Time:</b></td>
            <td class="custom-summary-td">{{this.delivertDateAndTime}}</td>
          </tr>
          <tr class="custom-summary-tr">
            <td class="custom-summary-td"><b>Sub Total:</b></td>
            <td class="custom-summary-td">{{ subTotalAmount + totalProductDiscount | currency: currency:'symbol':'1.2-2' }}</td>
          </tr>
          <tr class="custom-summary-tr" *ngIf="totalProductDiscount !== 0">
            <td class="custom-summary-td"><b>Product Discount:</b></td>
            <td class="custom-summary-td">(-) {{ totalProductDiscount | currency: currency:'symbol':'1.2-2' }}</td>
          </tr>
          <tr class="custom-summary-tr" *ngIf="discountPercentage">
            <td class="custom-summary-td"><b>Special Offer ({{order.couponCode}}):</b></td>
            <td class="custom-summary-td">(-) {{ order.discountAmount | currency: currency:'symbol':'1.2-2' }}</td>
          </tr>
          <tr class="custom-summary-tr">
            <td class="custom-summary-td"><b>Tax Amount:</b></td>
            <td class="custom-summary-td">{{ this.order.taxAmount | currency: currency:'symbol':'1.2-2' }}</td>
          </tr>
          <tr class="custom-summary-tr" *ngIf="homeDelivery">
            <td class="custom-summary-td"><b>Delivery Charge:</b></td>
            <td class="custom-summary-td">{{order.deliveryChargeAmount | currency: currency:'symbol':'1.2-2'}}</td>
          </tr>
          <tr class="custom-summary-tr">
            <td class="custom-summary-td"><b>Total Amount:</b></td>
            <td class="custom-summary-td">{{ this.totalAmount | currency: currency:'symbol':'1.2-2' }}</td>
          </tr>
          <tr class="custom-summary-tr">
            <h6 style="margin-top: 10px;">Would you like to add your customer details? <a (click)="viewCustomerDetails()" style="margin-left: 10px;
    text-decoration: underline;">Click here</a></h6>
          </tr>
        </table>
        
        
        <!-- Card for mobile view -->
        <div class="custom-order-summary-card">
          <div class="custom-card-item">
            <span class="custom-card-label">Order Date:</span>
            <span class="custom-card-value">{{ this.orderDateUI }}</span>
          </div>
          <!-- <div class="custom-card-item">
            <span class="custom-card-label">Prepare Time:</span>
            <span class="custom-card-value">
              <span *ngIf="this.prepareDay != 0">{{this.prepareDay}} Days </span>
              <span *ngIf="this.prepareHour != 0">{{ this.prepareHour }} Hours </span>
              <span *ngIf="this.prepareMinute != 0">{{this.prepareMinute}} Minutes </span>
            </span>
          </div>
          <div class="custom-card-item">
            <span class="custom-card-label">Delivery Duration:</span>
            <span class="custom-card-value">
              <span *ngIf="this.leadDayTotal != 0">{{this.leadDayTotal}} Days </span>
              <span *ngIf="this.leadHourTotal != 0">{{ this.leadHourTotal }} Hours </span>
              <span *ngIf="this.leadMinTotal != 0">{{this.leadMinTotal}} Minutes </span> to 
              <span *ngIf="this.leadMaxDayTotal != 0">{{ this.leadMaxDayTotal }} Days </span>
              <span *ngIf="this.leadMaxHourTotal != 0">{{this.leadMaxHourTotal }} Hours</span>
              <span *ngIf="this.leadMaxMinTotal != 0">{{this.leadMaxMinTotal}} Minutes </span>
            </span>
          </div> -->
          <div class="custom-card-item">
            <span class="custom-card-label">Pickup/Delivery Date & Time:</span>
            <span class="custom-card-value">{{this.delivertDateAndTime}}</span>
          </div>
          <div class="custom-card-item">
            <span class="custom-card-label">Sub Total:</span>
            <span class="custom-card-value">{{ subTotalAmount + totalProductDiscount | currency: currency:'symbol':'1.2-2' }}</span>
          </div>
          <div class="custom-card-item" *ngIf="totalProductDiscount !== 0">
            <span class="custom-card-label">Product Discount:</span>
            <span class="custom-card-value">(-) {{ totalProductDiscount | currency: currency:'symbol':'1.2-2' }}</span>
          </div>
          <div class="custom-card-item" *ngIf="discountPercentage">
            <span class="custom-card-label">Special Offer ({{order.couponCode}}):</span>
            <span class="custom-card-value">(-) {{ order.discountAmount | currency: currency:'symbol':'1.2-2' }}</span>
          </div>
          <div class="custom-card-item">
            <span class="custom-card-label">Tax Amount:</span>
            <span class="custom-card-value">{{ this.order.taxAmount | currency: currency:'symbol':'1.2-2' }}</span>
          </div>
          <div class="custom-card-item" *ngIf="homeDelivery">
            <span class="custom-card-label">Delivery Charge:</span>
            <span class="custom-card-value">{{order.deliveryChargeAmount | currency: currency:'symbol':'1.2-2' }}</span>
          </div>
          <div class="custom-card-item">
            <span class="custom-card-label"><strong>Total Amount:</strong></span>
            <span class="custom-card-value"><strong>{{ this.totalAmount | currency: currency:'symbol':'1.2-2' }}</strong></span>
          </div>
          <h6 style="margin-top: 10px;">Would you like to add your customer details? <a (click)="viewCustomerDetails()" style="margin-left: 10px;
    text-decoration: underline;">Click here</a></h6>
        </div>
        
   

    

    </div>


        <!-- Content -->

        <div class="col-lg-12 col-md-12 padding-right-30">
          
     
          <form class="form" (ngSubmit)="orderForm.form.valid && onSubmit(orderForm)" #orderForm="ngForm">
            
            <div class="checkout-card card" *ngIf="customerDetailsView">
              <!-- <h6>Do you want to add your details? <a (click)="viewCustomerDetails()" style="margin-left: 10px;
    text-decoration: underline;">Click here</a></h6> -->
    <h4 style="text-align: center;">Customer Details</h4>
            <div class="row" >

              <div class="col-md-6" >
                <div class="form-group">
                  <label class="form-label" for="firstName">First Name</label>
                  <input type="text" placeholder="First Name" id="firstName"
                    class="form-control form-control-alternative" [(ngModel)]="order.firstName" #firstName="ngModel"
                    name="firstName" >
                  <span class="text-danger"
                    *ngIf="(firstName.touched ) && firstName.errors?.required">
                    First Name is required
                  </span>
                </div>
              </div>
              <div class="col-md-6" >
                <div class="form-group">
                  <label class="form-label" for="lastName">Last Name</label>
                  <input type="text" placeholder="Last Name" id="lastName" class="form-control form-control-alternative"
                    name="lastName" [(ngModel)]="order.lastName" #lastName="ngModel">
                  <span class="text-danger"
                    *ngIf="(lastName.touched ) && lastName.errors?.required">
                    Last Name is required
                  </span>
                </div>
              </div>
              <div class="col-md-6" >
                <div class="form-group">
                  <label class="form-label" for="email">Email</label>
                  <input type="email" placeholder="E-mail" id="email" class="form-control form-control-alternative"
                    name="email" [(ngModel)]="order.email" #email="ngModel"  email 
                    [readonly]="order.email !== '' && verifyOption == 'email' ">
                  <span class="text-danger" *ngIf="(email.touched ) && email.errors?.required">
                    Email is required
                  </span>
                  <span class="text-danger" *ngIf="(email.touched ) && email.errors?.email">
                    Enter a valid email
                  </span>
                </div>
              </div>
              <div class="col-md-6" >
                <div class="form-group">
                  <label class="form-label" for="mobile">Phone</label>
                  <input *ngIf="order.mobile == ''" type="text" ng2TelInput
                  [ng2TelInputOptions]="{initialCountry: 'in'}" (hasError)="phoneHasError($event)"
                    (ng2TelOutput)="getNumber($event)" id="mobile" class="form-control form-control-alternative"
                    id="mobile" name="mobile" required>
                  <input *ngIf="order.mobile !== ''" type="number" placeholder="Phone" class="form-control form-control-alternative"
                    [(ngModel)]="order.mobile" #mobile="ngModel" id="mobile" name="mobile" 
                    >
                  <span class="text-danger" *ngIf="!mobileHasError">
                    Invalid Phone number
                  </span>
                  <!-- <span class="text-danger"
										*ngIf="(mobile.touched || orderForm.submitted) && mobile.errors?.required">
										Phone is required
									</span> -->
                </div>
              </div>
              <div class="col-md-12">
                <label class="form-label" for="mobile">Special Notes</label>
                <textarea type="text" placeholder="Special Notes" class="form-control form-control-alternative mb-3"
                  id="note" name="note" [(ngModel)]="order.specialNotes" #email="ngModel"></textarea>
                  
              </div>
           
              <div class="col-md-12">
                <label class="form-label" for="mobile" *ngIf="promoCode">Promotion</label>
                <!-- <input type="text" placeholder="Promo Code"
									class="form-control form-control-alternative mb-3" id="note" name="promoCode"
									[(ngModel)]="promoCode" />
							<button  class="btn btn-primary" type="button"  (click)="applyPromoCode()" [disabled]="promoCode == null">Apply</button>
							 -->
                <ul class="form-check" ngbRadioGroup name="radioBasic" [(ngModel)]="promoCode">
									<!-- <li>
										<div>
											<label ngbButtonLabel class="form-check-label text-default "
												style="padding: 0; white-space: normal ; " (click)="applyPromoCode('')">
												<input ngbButton type="radio" class="form-check-input" [value]="">
												No offer</label>
										</div>
									</li> -->
									<li *ngFor="let offer of businessOfferDto">
										<div>
											<label ngbButtonLabel class="form-check-label text-default "
												style="padding: 0; white-space: normal ; "
												(click)="applyPromoCode(offer)">
												<input ngbButton type="radio" class="form-check-input" [value]="offer">
												{{ offer.name }} Code: {{offer.couponCode}}</label>
										</div>
									</li>
								</ul>
								<ngb-alert class="mt-3" *ngIf="promoMessage != ''" [type]="['info']"
									(close)="promoMessage == ''" (click)="promoMessage = ''">
									<strong>Promo Code: </strong> {{promoMessage}}</ngb-alert>
                  
              </div>
            </div>

         
     
          </div>

            <h5 class="mb-2" style="text-align: center; margin-top: 40px;" >Delivery Option</h5>
            <div class="checkout-card card" >
            <div class="nav-wrapper" >
              <ul class="nav nav-pills nav-fill flex-column flex-md-row" id="tabs-icons-text" role="tablist">
                <li class="nav-item">
                  <a class="nav-link mb-sm-3 mb-md-0 active" id="tabs-text-1-tab" data-toggle="tab" href="#tabs-text-1"
                    role="tab" aria-controls="tabs-icons-text-2" aria-selected="false" (click)="dineIn()"><i
                      class="fa fa-  mr-2"></i>
                     Dine In
                      </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link mb-sm-3 mb-md-0 " id="tabs-text-2-tab" data-toggle="tab" href="#tabs-text-2"
                    role="tab" aria-controls="tabs-icons-text-2" aria-selected="false" (click)="pickFromStore()"><i
                      class="fa fa-  mr-2"></i>
                     Pick From Store
                      </a>
                </li>
                <!-- <li class="nav-item" *ngIf="deliveryOption !== null" >
                  <a class="nav-link mb-sm-3 mb-md-0 " id="tabs-text-3-tab" data-toggle="tab" href="#tabs-text-3"
                    role="tab" aria-controls="tabs-icons-text-1" aria-selected="true" (click)="onHomeDelivery()"><i
                      class="fa fa-credit-card mr-2"></i>Home Delivery</a>
                </li> -->


              </ul>
            </div>

            <div class="card shadow" >
              <div class="card-body">
                <div class="tab-content" id="myTabContent" style="padding: 0px !important;">
                  <div class="tab-pane fade show active" id="tabs-text-1" role="tabpanel"
                  aria-labelledby="tabs-text-1-tab">
               <div class="card-container">
                <div class="row card-container">
               <!-- Select Table Dropdown -->
                <div class="form-group">
                  <label for="tableSelect">Select Table</label>
                  <select class="form-control" id="tableSelect" 
                          [required]="this.order.deliveryMethod === 'Dine In'" 
                          
                          (change)="selectTable($event.target.value)">
                    <option value="" disabled selected>Select a table</option>
                    <option *ngFor="let item of resourceList" [value]="item.resourceName" 
                            [selected]="tableNumber === item.resourceName">
                      {{ item.resourceName }}
                    </option>
                  </select>
                  <!-- <div *ngIf="this.order.deliveryMethod === 'Dine In' && !tableNumber" class="invalid-feedback">
                    Please select a table for Dine In.
                  </div> -->
                </div>

                <!-- Place Order Button -->
               

                  
                </div>
                
               </div>
             </div>
             
                  <div class="tab-pane fade show " id="tabs-text-2" role="tabpanel"
                    aria-labelledby="tabs-text-2-tab">
                    <h6>Pickup Address:</h6>
                    <p *ngIf="businessUser.address" class="description">
                      {{businessUser.address.streetNumber? businessUser.address.streetNumber + ', ' : '' }}
                      {{businessUser.address.streetName? businessUser.address.streetName + ', ' : '' }}
                      {{businessUser.address.suburb? businessUser.address.suburb + ', ' : '' }}
                      {{businessUser.address.locality? businessUser.address.locality + ', ' : '' }}
                      {{businessUser.address.city? businessUser.address.city + ', ' : '' }}
                      {{ businessUser.address.country? businessUser.address.country : '' }}
                    </p>
                  </div>
                  <div class="tab-pane fade" id="tabs-text-3" role="tabpanel" aria-labelledby="tabs-text-3-tab">
                    <form class="form" (ngSubmit)="addressForm.form.valid && onSubmit(orderForm)" #addressForm="ngForm">
                    <div class="mb-4">
                      <h4 class="mb-4">Delivery Address</h4>
                      <!-- Row -->
                      <div class="row mb-4">
                        <h6></h6>
                        <!-- streetNumber -->
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="streetNumber">Flat / House No</label>
                            <input type="text" id="streetNumber" placeholder="Flat / House No"
                              class="form-control form-control-alternative" [(ngModel)]="address.streetNumber"
                              type="text" name="streetNumber" #streetNumber="ngModel">
                            <span class="text-danger"
                              *ngIf="(streetNumber.touched || addressForm.submitted) && streetNumber.errors?.required">
                              Street Number is required
                            </span>
                          </div>
                        </div>
                        <!-- streetName -->
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="streetName">Floor Number / Tower Number</label>
                            <input type="text" id="streetName" placeholder="Floor Number / Tower Number"
                              class="form-control form-control-alternative" [(ngModel)]="address.streetName" type="text"
                              name="streetName" #streetName="ngModel">
                            <span class="text-danger"
                              *ngIf="(streetName.touched || addressForm.submitted) && streetName.errors?.required">
                              Street Name is required
                            </span>
                          </div>
                        </div>


                        <!-- addressLine1 -->
                        <!-- <div class="col-md-6">
                          <div class="form-group">
                            <label for="addressLine1">Address Line 1</label>
                            <input type="text" required id="addressLine1" placeholder="Address Line 1"
                              class="form-control form-control-alternative" [(ngModel)]="address.addressLine1"
                              type="text" name="addressLine1" #addressLine1="ngModel">
                            <span class="text-danger"
                              *ngIf="(addressLine1.touched || orderForm.submitted) && addressLine1.errors?.required">
                              Address Line 1 is required
                            </span>
                            <input type="text" placeholder="Address" class="form-control form-control-alternative">
                          </div>
                        </div> -->
                        <!-- addressLine2 -->
                        <!-- <div class="col-md-6">
                          <div class="form-group">
                            <label for="addressLine2">Address Line 2</label>
                            <input type="text" id="addressLine2" placeholder="Address Line 2"
                              class="form-control form-control-alternative" [(ngModel)]="address.addressLine2"
                              type="text" name="addressLine2" #addressLine2="ngModel">
                            <span class="text-danger"
                              *ngIf="(addressLine2.touched || orderForm.submitted) && addressLine2.errors?.required">
                              Address Line 2 is required
                            </span>
                            <input type="text" placeholder="Address" class="form-control form-control-alternative">
                          </div>
                        </div> -->

  <!-- suburb -->
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="locality">Landmark / Area</label>
                            <!-- <select class="custom-select form-control-alternative mb-3" id="suburb" name="suburb"
                              [(ngModel)]="address.suburb" #suburb="ngModel" aria-placeholder="Select Suburb">
                              <option label="Select Suburb" value="">Select Suburb
                              </option>
                              <option *ngFor="let types of suburbsLists.suburbs" [value]="types">
                                {{ types }}</option>
                            </select> -->
                            <input type="text" id="suburb" placeholder="Landmark / Area"
                            class="form-control form-control-alternative" [(ngModel)]="address.suburb" type="text"
                            name="suburb" #suburb="ngModel" required>
                            <span class="text-danger"
                              *ngIf="(suburb.touched || addressForm.submitted) && suburb.errors?.required">
                              Suburb is required
                            </span>
                          </div>
                        </div>

                        <!-- City -->
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="locality">City</label>
                            <!-- <select class="custom-select form-control-alternative mb-3" id="city" name="city"
                              [(ngModel)]="address.city" #city="ngModel" aria-placeholder="Select City"
                              (change)="onLocalitySelect($event.target.value)">
                              <option label="Select City" value="">Select City</option>
                              <option *ngFor="let types of localityList" [value]="types.city">
                                {{ types.city }}
                              </option>
                            </select> -->
                            <input type="text" id="city" placeholder="City"
                            class="form-control form-control-alternative" [(ngModel)]="address.city" type="text"
                            name="city" #city="ngModel" required>
                            <span class="text-danger"
                              *ngIf="(city.touched || addressForm.submitted) && city.errors?.required">
                              City is required
                            </span>
                          </div>
                        </div>

                        <!-- locality-->
                        <!-- <div class="col-md-6">
												<label for="locality">Metropolitan Area</label>
												<select class="custom-select form-control-alternative mb-3"
													[(ngModel)]="address.locality" required type="text" id="locality" name="locality"
													#locality="ngModel">
			
													<option value="Auckland">Auckland</option>
												</select>
												<span class="text-danger"
													*ngIf="(locality.touched || orderForm.submitted) && locality.errors?.required">
													Locality is required
												</span>
											</div> -->

                      
                        <!-- State -->
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="zip">State</label>
                            <!-- <select class="custom-select form-control-alternative mb-3" [(ngModel)]="address.state"
                              required type="text" id="state" name="state" #state="ngModel">
                              <option value="Auckland">Auckland</option>
                            </select> -->
                            <input type="text" id="state" placeholder="State"
                            class="form-control form-control-alternative" [(ngModel)]="address.state" type="text"
                            name="state" #state="ngModel" required>
                            <span class="text-danger"
                              *ngIf="(state.touched || addressForm.submitted) && state.errors?.required">
                              State is required
                            </span>

                          </div>
                        </div>
                        <!-- Country -->
                        <div class="col-md-6">
                          <label for="country">Country</label>
                          <select class="custom-select form-control-alternative mb-3" required
                            [(ngModel)]="address.country" place name="country" id="country" #country="ngModel">

                            <option value="Australia">Australia</option>
                            <option value="Bangladesh">Bangladesh</option>
                            <option value="Canada">Canada</option>
                            <option value="France">France</option>
                            <option value="Fiji">Fiji</option>
                            <option value="Germany">Germany</option>
                            <option value="India">India</option>
                            <option value="Italy">Italy</option>
                            <option value="New Zealand">New Zealand</option>
                            <option value="South Africa">South Africa</option>
                            <option value="Spain">Spain</option>
                            <option value="Sri Lanka">Sri Lanka</option>
                            <option value="Thailand">Thailand</option>
                            <option value="United Arab Emirates">United Arab Emirates</option>
                            <option value="United Kingdom">United Kingdom</option>
                            <option value="United States">United States</option>

                          </select>
                          <span class="text-danger"
                            *ngIf="(country.touched || addressForm.submitted) && country.errors?.required">
                            Country is required
                          </span>
                        </div>
                        <!-- postcode -->
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="postcode">Post Code</label>
                            <input type="text" id="postcode" placeholder="Post Code"
                              class="form-control form-control-alternative" [(ngModel)]="address.postcode"
                              name="postcode" #postcode="ngModel">
                            <span class="text-danger"
                              *ngIf="(postcode.touched || addressForm.submitted) && postcode.errors?.required">
                              Post Code is required
                            </span>
                          </div>
                        </div>
                      </div>
                      <!-- Row / End -->
                      <div class="row">
                        <div class="col-md-12">
                          <label for="postcode">Delivery Options</label>
                          <div  class=" " ngbRadioGroup name="radioBasic" [(ngModel)]="deliveryChargeOption"  (change)="setDeliveryCharge(deliveryChargeOption)">

                            <ng-container *ngFor="let delivery of deliveryOption">
                              <label ngbButtonLabel class="btn p-1">
                                <input ngbButton  type="radio" [value]="delivery.code">Delivery Charges: {{delivery.charges| currency: currency:'symbol':'1.2-2'}} - Code:
                                {{delivery.code}}
                              </label>
                              <div>{{delivery.description}}</div>
                              <div [innerHTML]="delivery.policy"></div>
                            </ng-container>

                          </div>
                        </div>
                      </div>
                    </div>
                    </form>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12" style="display: flex; justify-content: center;">
            <button class="btn btn-primary" type="button"
              [disabled]=" paymentLoader || submitButtonDisable"
              (click)="onCashPaymentSubmit(content)">
              Place Order
            </button>
          </div>
          </form>
          <ngb-alert *ngIf="showAlert" [type]="alertType" (close)="showAlert = false">
            <strong>{{headerTitle}}</strong> {{bodyMessage}}</ngb-alert>
     
        </div>


      </div>
    </div>
  </div>
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 *ngIf="isSuccess" style="color: green;" class="modal-title">{{ headerTitle }}</h4>
    <h4 *ngIf="!isSuccess" style="color: red;" class="modal-title">{{ headerTitle }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ bodyMessage }}&hellip;</p>
  </div>
  <div class="modal-footer">
    <button *ngIf="isSuccess" type="button" class="btn btn-light" (click)="ClosedSuccess()">Close</button>
    <button *ngIf="!isSuccess" type="button" class="btn btn-light" (click)="Closed()">Close</button>
    <!-- <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button> -->
  </div>
</ng-template>