<header *ngIf="display" class="custom-sticky-header">
  <div class="custom-container d-flex justify-content-between align-items-center">
    
    <!-- Left side: Logo and Property Name -->
    <div class="custom-header-left d-flex align-items-center">
      <img src="{{businessUser.logoUrl}}"  class="custom-logo" />
      <span class="custom-property-name">
        {{businessUser?.name}}
      </span>
    </div>

    <!-- Right side: Menu and Cart Icons -->
    <div class="custom-header-right d-flex align-items-center" style="gap:10px">
      <span  class="menu-icon" (click)="toggleDrawer()">
        &#9776;
      </span>

      <span class="cart-icon" (click)="openCartModal()">
        <span class="cart-count" *ngIf="addToCartList.length > 0">{{ calculateQuantity() }}</span>
        <svg xmlns="http://www.w3.org/2000/svg" height="35" viewBox="0 0 24 24" width="35">
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zM1 2v2h2l3.6 7.59-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h12v-2H7.42c-.14 0-.25-.11-.25-.25l.03-.12.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.08-.14.12-.31.12-.48 0-.55-.45-1-1-1H5.21l-.94-2H1zm16 16c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z" fill="#FFFFFF" />
        </svg>
      </span>
    </div>
  </div>
</header>



<!-- Drawer -->
<div class="drawer-backdrop" *ngIf="drawerOpen" (click)="closeDrawer()">
  <div class="drawer" (click)="$event.stopPropagation()">
    <div  class="close-icon" (click)="closeDrawer()">
      &times; <!-- Cross Icon -->
    </div>
    <!-- Drawer Content -->
    <div style="margin-top: 20px; margin-bottom: 40px;">
      <div style="display: flex; justify-content: center; margin-bottom: 20px;">
        <img *ngIf="businessUser.logoUrl != null"
          style="height: 100px; width: 100px; border: 2px solid gray; border-radius: 50px;"
          src="{{businessUser.logoUrl}}">

        <img *ngIf="businessUser.logoUrl  == null" src="assets/images/app-logo.png" alt=""
          style="height: 100px; width: 100px; border: 2px solid gray; border-radius: 50px;" />

      </div>
      <p *ngIf="businessUser.seoFriendlyName  != null || businessUser.seoFriendlyName  != undefined" style="text-align: center;
      font-weight: 700;
      font-size: 19px;">{{businessUser.name}}</p>
      <ng-container *ngFor="let productGroups of productGroupsList; let i = index">
        <ng-container *ngFor="let group of productGroups; let j = index">
          <div *ngIf="group.isSubGroup != true" style="display: flex; justify-content: center; cursor: pointer; "
            [ngClass]="{'active-menu': isActive(j)}" (click)="selectCard(j)">
            <p style="margin-top: 1rem !important;  cursor: pointer; " >{{ group.name }}</p>
          </div>
        </ng-container>
      </ng-container>
    </div>

  </div>
</div>

<!-- Page Content -->
<!-- <div class="promo-container text-white py-5" *ngIf="display" style="margin-top: 30px;">
  <div class="container d-flex flex-column flex-lg-row align-items-center justify-content-between">

    <div class="promo-image position-relative order-1 order-lg-2" *ngIf="businessUser.logoUrl != null">
      <img src="{{businessUser.logoUrl}}" alt="Pizza" class="img-fluid pizza-img" />
    </div>
    <div class="promo-image position-relative order-1 order-lg-2" *ngIf="businessUser.logoUrl == null">
      <img src="assets/images/imagefood.png" alt="Pizza" class="img-fluid pizza-img" />
    </div>

  
    <div class="promo-details text-center text-md-center text-lg-left text-2xl-left  text-xl-left text-sm-center text-lg-start order-2 order-lg-1">
      <h1 class="fw-bold text-white">{{businessUser.name}}</h1>
      <p class="promo-description mt-3 mb-4">
        Experience delicious, freshly crafted meals at our restaurant, where quality ingredients meet vibrant flavors.
        Whether you're craving classic favorites or exciting new dishes, our warm ambiance and friendly service make
        every meal memorable. Join us for a delightful dining experience that satisfies both the palate and the soul.
      </p>
      <button class="btn btn-warning text-dark fw-bold px-4 py-2 promo-btn" (click)="scrollToProducts()"
        style="color: red !important;">Order Now</button>
    </div>
  </div>
</div> -->


<div *ngIf="display" class="menu-margin menuSection" style="position: relative;" #menuSection>

  <h1 class="headingMenu">Our Menu</h1>

  <div class="cardlistmenu scroll-container" #scrollContainer>
    <ng-container *ngFor="let productGroups of productGroupsList; let i = index">
      <ng-container *ngFor="let group of productGroups; let j = index">
        <div *ngIf="group.isSubGroup != true">
          <div class="cardDesignmenu" [class.highlightedcolorcard]="selectedIndex1 === j"
            [class.blur-card]="selectedIndex1 !== j" (click)="selectCard(j)">
            <img *ngIf="group.imageUrl" [src]="group.imageUrl" alt="{{ group.name }}" class="imageitemmenu" />
            <img *ngIf="!group.imageUrl" src="assets/images/foodBydefault.png" alt="default" class="imageitemmenu" />
          </div>
          <h3 class="menuheading">{{ group.name }}</h3>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <!-- Left scroll button -->
  <div class="scroll-button left-button" (click)="scrollLeft(scrollContainer)">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
      stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon">
      <line x1="19" y1="12" x2="5" y2="12"></line>
      <polyline points="12 5 5 12 12 19"></polyline>
    </svg>
  </div>

  <!-- Right scroll button -->
  <div class="scroll-button right-button" (click)="scrollRight(scrollContainer)">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
      stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon">
      <line x1="5" y1="12" x2="19" y2="12"></line>
      <polyline points="12 5 19 12 12 19"></polyline>
    </svg>
  </div>

</div>




<div *ngIf="display" class="menu-content-section">
  <div class="search-bar" style="width: 80%; margin: auto; margin-bottom: 20px; position: relative;">
    <!-- Search Input Field with Darker Text and Search Icon -->
    <input type="text" 
           placeholder="Search products..." 
           [(ngModel)]="searchTerm" 
           (ngModelChange)="filterProducts()"
           class="form-control" 
           style="padding-left: 40px; font-weight: bold; color: #333; font-size: 16px; border-radius: 5px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);">
  
    <!-- Search Icon -->
    <svg xmlns="http://www.w3.org/2000/svg" 
     style="position: absolute; left: 10px; top: 50%; transform: translateY(-50%); color: #333;" 
     fill="currentColor" 
     class="bi bi-search" 
     viewBox="0 0 16 16" width="16" height="16">
  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zm-5.442.657a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11z"/>
</svg>


  </div>
  
  <ng-container *ngIf="searchTerm">
    <div class="d-flex flex-wrap" style="gap: 15px; justify-content: center;">
      <ng-container *ngFor="let productGroups of filteredProductGroupsList">
        <ng-container *ngFor="let group of productGroups">
          <ng-container *ngIf="group.isSubGroup != true">
          <ng-container *ngFor="let product of group.productDtoList; let k = index">
            <ng-container *ngIf="product.name.toLowerCase().includes(searchTerm.toLowerCase())">
              <div class="product-card products-cards" 
               style="background: rgba(255, 255, 255, 0.5); 
                      border: 1px solid rgba(0, 0, 0, 0.02); 
                     
                      box-shadow: 3px 3px 2.5px rgb(0 0 0 / 25%), 
                      inset 1px 1.5px 4px rgb(0 0 0 / 25%);
                      border-radius: 10px; 
                      transition: transform 0.3s ease, background-color 0.3s ease;">
                        <div
                        *ngIf="product.productVariationDtoList.length > 0 && product.name.toLowerCase().includes(searchTerm.toLowerCase())">
                        <div style="width: 100%; margin: auto;">
                          <img *ngIf="product.imageList[0] != null" src="{{product.imageList[0].url}}"
                            style="border-radius: 8px;  object-fit: cover; width: 100%; height: 155px;"
                            (click)="open(content, product.imageList[0].url, product.name)">
                          <img *ngIf="product.imageList[0] == null"
                            style="border-radius: 8px; object-fit: cover; width: 100%; height: 155px;"
                            src="assets/images/product.png">
                        </div>
                        <div class="px-3">
                          <h5 style="padding-right: 30px; text-align: left;">{{ product.name }}</h5>
                          <!-- <p *ngIf="product.shortDescription">{{ product.shortDescription }}</p>
                          <p *ngIf="product.description">{{ product.description }}</p> -->
          
                          <!-- Product Variations -->
                          <div *ngFor="let item of product.productVariationDtoList; let l = index">
                            <h6 class="text-primary">{{ item.name }}
                              <button *ngIf="!isVariationInCart(item)"
                                class="product-btn btn btn-outline-primary btn-rounded btn-sm pull-right"
                                (click)="addProductWithVariationToCart(product, item, l)">
                                <i class="fa fa-plus"></i>
                              </button>
                              <button *ngIf="isVariationInCart(item)"
                                class="product-btn btn btn-primary btn-rounded btn-sm pull-right"
                                style="background-color: gray !important;">
                                <i class="fa fa-plus"></i>
                              </button>
                              <b class="d-block pull-right mr-2 text-primary">
                                <div *ngIf="item.discountedPrice">
                                  <div class="product-card-price-before-discount cl-neutral-secondary f-14 fw-light">
                                    {{item.sellUnitPrice | currency: businessUser.localCurrency.toUpperCase():'symbol':'1.2-2'}}
                                  </div>
                                  <div class="product-card-price cl-neutral-primary f-14 fw-light pl-2">
                                    {{item.discountedPrice | currency: businessUser.localCurrency.toUpperCase():'symbol':'1.2-2'}}
                                  </div>
                                </div>
                                <div *ngIf="!item.discountedPrice">
                                  <div class="product-card-price cl-neutral-primary f-14 fw-light">
                                    {{item.sellUnitPrice | currency: businessUser.localCurrency.toUpperCase():'symbol':'1.2-2'}}
                                  </div>
                                </div>
                              </b>
                            </h6>
                          </div>
                        </div>
                      </div>
          
                      <!-- If there are no variations, show the flex-wrap card layout -->
                      <div
                        *ngIf="product.productVariationDtoList.length === 0 && product.name.toLowerCase().includes(searchTerm.toLowerCase())">
                        <div style="width: 100%;  margin: auto;">
                          <img *ngIf="product.imageList[0] != null" src="{{product.imageList[0].url}}"
                            style="border-radius: 8px;  object-fit: cover; width: 100%; height: 180px;"
                            (click)="open(content, product.imageList[0].url, product.name)">
                          <img *ngIf="product.imageList[0] == null"
                            style="border-radius: 8px;  object-fit: cover; width: 100%; height: 180px;"
                            src="assets/images/product.png">
                        </div>
                        <div class="px-3">
                          <h5 style="text-align: left; font-size: 16px;">{{ product.name }}</h5>
                          <!-- <p *ngIf="product.productCode" style="margin-top: -5px;">Code: {{ product.productCode }}</p> -->
                          <!-- <p *ngIf="product.shortDescription" style="margin-bottom: 0px !important;">{{ product.shortDescription
                            }}</p>
                          <p *ngIf="product.description" style="margin-bottom: 0px !important;">{{ product.description }}</p> -->
          
                          <!-- Prices -->
                          <h6 class="text-primary" style="font-weight: bold;">
                            <div class="d-flex fd-row" *ngIf="product.discountedPrice" style="margin-top: -5px;">
                              <div class="product-card-price-before-discount cl-neutral-secondary f-14 fw-light">
                                {{product.sellUnitPrice | currency: businessUser.localCurrency.toUpperCase():'symbol':'1.2-2'}}
                              </div>
                              <div class="product-card-price cl-neutral-primary f-14 fw-light pl-2">
                                {{product.discountedPrice | currency: businessUser.localCurrency.toUpperCase():'symbol':'1.2-2'}}
                              </div>
                            </div>
                            <div class="box-flex fd-row" *ngIf="!product.discountedPrice">
                              <div class="product-card-price cl-neutral-primary f-14 fw-light">
                                {{product.sellUnitPrice | currency: businessUser.localCurrency.toUpperCase():'symbol':'1.2-2'}}
                              </div>
                            </div>
                            <span class="add-to-cart-container">
                              <button *ngIf="!isProductInCart(product)" class="card-menu-button"
                                (click)="addProductToCart(product, variation, i)">Add to cart</button>
                              <button *ngIf="isProductInCart(product)" class="card-menu-button"
                                >Added</button>
                            </span>
                          </h6>
                        
                        </div>
                        
                      </div>
                    </div>  
              </ng-container>
            </ng-container> 
          </ng-container>
          </ng-container>
      </ng-container>
    </div>
   
  </ng-container>


  <ng-container *ngIf="!searchTerm || searchTerm.trim() === ''">
    <div class="tab-content" id="v-pills-tabContent" style="padding: 0px;"
      *ngFor="let productGroups of filteredProductGroupsList; let i = index">
      <div class="collapse" id="collapseGroup{{i}}{{j}}" [class.active]='selectedIndex1 === j'
        *ngFor="let group of productGroups; let j = index">

        <div class="d-flex flex-wrap" style="gap: 15px; justify-content: center;">
          <div *ngFor="let product of group.productDtoList; let k = index" class="product-card products-cards" style="background: rgba(255, 255, 255, 0.5); border: 1px solid rgba(0, 0, 0, 0.02); 
                      
                    box-shadow: 3px 3px 2.5px rgb(0 0 0 / 25%),  
                inset 1px 1.5px 4px rgb(0 0 0 / 25%); border-radius: 10px;
                transition: transform 0.3s ease, background-color 0.3s ease;">

            <!-- If there are variations, show the single card layout -->
            <div
              *ngIf="product.productVariationDtoList.length > 0 && product.name.toLowerCase().includes(searchTerm.toLowerCase())">
              <div style="width: 100%; margin: auto;">
                <img *ngIf="product.imageList[0] != null" src="{{product.imageList[0].url}}"
                  style="border-radius: 8px;  object-fit: cover; width: 100%; height: 155px;"
                  (click)="open(content, product.imageList[0].url, product.name)">
                <img *ngIf="product.imageList[0] == null"
                  style="border-radius: 8px; object-fit: cover; width: 100%; height: 155px;"
                  src="assets/images/product.png">
              </div>
              <div class="px-3">
                <h5 style="padding-right: 30px; text-align: left; font-size: 16px;">{{ product.name }}</h5>
                <!-- <p *ngIf="product.shortDescription">{{ product.shortDescription }}</p>
                <p *ngIf="product.description">{{ product.description }}</p> -->

                <!-- Product Variations -->
                <div *ngFor="let item of product.productVariationDtoList; let l = index">
                  <h6 class="text-primary">{{ item.name }}
                    <button *ngIf="!isVariationInCart(item)"
                      class="product-btn btn btn-outline-primary btn-rounded btn-sm pull-right"
                      (click)="addProductWithVariationToCart(product, item, l)">
                      <i class="fa fa-plus"></i>
                    </button>
                    <button *ngIf="isVariationInCart(item)"
                      class="product-btn btn btn-primary btn-rounded btn-sm pull-right"
                      style="background-color: gray !important;">
                      <i class="fa fa-plus"></i>
                    </button>
                    <b class="d-block pull-right mr-2 text-primary">
                      <div *ngIf="item.discountedPrice">
                        <div class="product-card-price-before-discount cl-neutral-secondary f-14 fw-light">
                          {{item.sellUnitPrice | currency: businessUser.localCurrency.toUpperCase():'symbol':'1.2-2'}}
                        </div>
                        <div class="product-card-price cl-neutral-primary f-14 fw-light pl-2">
                          {{item.discountedPrice | currency: businessUser.localCurrency.toUpperCase():'symbol':'1.2-2'}}
                        </div>
                      </div>
                      <div *ngIf="!item.discountedPrice">
                        <div class="product-card-price cl-neutral-primary f-14 fw-light">
                          {{item.sellUnitPrice | currency: businessUser.localCurrency.toUpperCase():'symbol':'1.2-2'}}
                        </div>
                      </div>
                    </b>
                  </h6>
                </div>
              </div>
            </div>

            <!-- If there are no variations, show the flex-wrap card layout -->
            <div
            *ngIf="product.productVariationDtoList.length === 0 && product.name.toLowerCase().includes(searchTerm.toLowerCase())">
            <div style="width: 100%; margin: auto;">
              <img *ngIf="product.imageList[0] != null" src="{{product.imageList[0].url}}"
                style="border-radius: 8px; object-fit: cover; width: 100%; height: 180px;"
                (click)="open(content, product.imageList[0].url, product.name)">
              <img *ngIf="product.imageList[0] == null" style="border-radius: 8px; object-fit: cover; width: 100%; height: 180px;"
                src="assets/images/product.png">
            </div>
            
            <!-- Use flexbox for card layout -->
            <div class="card-container px-3">
              <h5 style="text-align: left;font-size: 16px;">{{ product.name }}</h5>
              <!-- <p *ngIf="product.shortDescription" style="margin-bottom: 0px !important;">{{ product.shortDescription }}</p>
              <p *ngIf="product.description" style="margin-bottom: 0px !important;">{{ product.description }}</p> -->
          
              <!-- Prices -->
              <h6 class="text-primary" style="font-weight: bold;">
                <div class="d-flex fd-row" *ngIf="product.discountedPrice" style="margin-top: -5px;">
                  <div class="product-card-price-before-discount cl-neutral-secondary f-14 fw-light">
                    {{product.sellUnitPrice | currency: businessUser.localCurrency.toUpperCase():'symbol':'1.2-2'}}
                  </div>
                  <div class="product-card-price cl-neutral-primary f-14 fw-light pl-2">
                    {{product.discountedPrice | currency: businessUser.localCurrency.toUpperCase():'symbol':'1.2-2'}}
                  </div>
                </div>
                <div class="box-flex fd-row" *ngIf="!product.discountedPrice">
                  <div class="product-card-price cl-neutral-primary f-14 fw-light">
                    {{product.sellUnitPrice | currency: businessUser.localCurrency.toUpperCase():'symbol':'1.2-2'}}
                  </div>
                </div>
                <span class="add-to-cart-container">
                  <button *ngIf="!isProductInCart(product)" class="card-menu-button"
                    (click)="addProductToCart(product, variation, i)">Add to cart</button>
                  <button *ngIf="isProductInCart(product)" class="card-menu-button">Added</button>
                </span>
              </h6>
          
              <!-- Add to cart button -->
              
            </div>
          </div>
          

          </div>
        </div>


        <!-- <p>Code : {{ group.productCode }}</p> -->
        <!-- <div class="collapse" [ngClass]="{'show': i==0}" id="collapseGroup{{i}}"> -->

        <!-- <span>  6</span> -->

      </div>
    

    </div>

  </ng-container>

  <div *ngIf="addToCartList.length > 0" class="modal" tabindex="-1" [ngClass]="{'show': isCartModalOpen}" >
    <div class="full-screen-cart-content">
     
      <div class="full-screen-cart-body">
        <div class="cart-items-widget bg-secondary mb-4" *ngIf="addToCartList.length > 0 && isCartModalOpen">
          <div class="form-group focused">
            <div class="input-group">
              <cartOne [products]="addToCartList" (productRemoved)="removeProduct($event)"
                (productVariationRemoved)="removeProductVariatiion($event)">
              </cartOne>
            </div>
          </div>
          <div style="display: flex; justify-content: center; gap:20px; margin-bottom: 53px;">
            
            <a (click)="closeCartModal()" *ngIf="addToCartList.length > 0" class="btn btn-1 btn-primary d-block order-now-btn" style="color:#fff">
              Go Back
            </a>

            <a (click)="onOrderClick()" *ngIf="addToCartList.length > 0" class="btn btn-1 btn-primary d-block order-now-btn" style="color:#fff">
              Order Now
            </a>
          </div>
          
        </div>
      </div>
    </div>
  </div>
  
  


  <div style="display: flex; justify-content: center; margin-top: 20px;" *ngIf="addToCartList.length > 0">
    <button style="padding: 11px;
    cursor: pointer;
    border-radius: 7px;
    color: white;
    background-color: #221950;
    font-weight: 600;" (click)="openCartModal()">Go To Cart</button>
  </div>

</div>


<div *ngIf="!display">
  <app-orderOne></app-orderOne>
</div>

<footer>
  <div class="col-md-12" style="background-color: #221950; ">
    <div class="copyrights text-white" style="border-top: none !important; margin-top: 10px;">
      Made with ❤ by
      <a href="https://www.credencesoft.co.nz/"><img class="sh-image-url" src="assets/images/cs-logo.png" height="25px"
          alt="CredenceSoft-outline" /></a><br />
      Powered by BookOne © 2024 {{ businessUser.name }}. All Rights
      Reserved.
    </div>
  </div>
</footer>