import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_URL, API_URL_PROMOTION } from '../app.component';
import { Room } from '../model/room';
import { Booking } from '../model/booking';
import { Msg } from '../model/msg';
import { Payment } from '../model/payment';

@Injectable({
  providedIn: 'root'
})
export class HotelBookingService {

  constructor(private http: HttpClient) { }
  getBookingDetails(bookingNumber: number, bookingEmail: string) {
 
    return this.http.get <any>(API_URL + '/api/website/findBookingByIdAndEmail?BookingReferenceNumber=' + bookingNumber + '&BookingEmail=' + bookingEmail,  { observe: 'response' });
  }
  getRoomDetailsByPropertyId(propertyId: number) {
    return this.http.get <Room[]>(API_URL + '/api/website/findAllRoomsByPropertyId/' + propertyId,  { observe: 'response' });
  }
  createBooking(booking: Booking) {
    return this.http.post<Booking>(API_URL + '/api/website/booking', booking, { observe: 'response' });
  }
  checkAvailability(booking: any) {
    return this.http.post<any> (API_URL + '/api/website/checkAvailability', booking ,  { observe: 'response' });
  }
  getRoomDetailsByPropertyIdAndDate(propertyId: number, fromDate: string, toDate: string) {
    return this.http.get <any[]>(API_URL + '/api/website/getAllRoomsByDate?PropertyId=' + propertyId + '&FromDate=' + fromDate + '&ToDate=' + toDate,  { observe: 'response' });
  }
  sendTextMessage(message: Msg ) {
    return this.http.post<Msg>(API_URL + '/api/website/message/send', message, { observe: 'response' });
  }

  getPlan( propertyId: string, roomId: string) {
    return this.http.get<any[]>(API_URL + '/api/website/property/'+propertyId+'/room/'+roomId+'/roomPlan',{ observe: 'response' });
  }
  processPayment(paymentDetails: Payment) {
    return this.http.post<Payment>(API_URL + '/api/website/processPayment', paymentDetails, { observe: 'response' });
}
  savePayment(paymentDetails: Payment) {
    return this.http.post<Payment>(API_URL + '/api/website/savePayment', paymentDetails, { observe: 'response' });
}
getOfferDetailsBySeoFriendlyName( seoName: string) {

  return this.http.get<any>(API_URL_PROMOTION + '/api/offer/findBySeofriendlyName/'+seoName+'/',{ observe: 'response' });
}
}
