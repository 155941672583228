
export class productVariationDtoList {

  id: number;
  buyUnitPrice: number;
  code:	string;
  discountedPrice:	number;
  productId:	number;
  sellUnitPrice:	number;
  unitsInOrder:	number;
  unitsInStock:	number;
  name:	string;
  quantityVariation: number;
  totalPrice: number;
  notes:	string;

  constructor() {}
}
