<div id="wrapper">
   <!-- Header of Admin Panel -->
   <app-admin-header></app-admin-header>
   <div class="clearfix"></div>
   <div id="dashboard">
      <!-- Sidebar of Admin Panel -->
      <app-admin-sidebar></app-admin-sidebar>
      <!--Main Container -->
      <router-outlet></router-outlet>
   </div>
</div>