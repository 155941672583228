import { Component, OnInit, AfterViewInit, ViewEncapsulation, Output, EventEmitter} from '@angular/core';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import { CookieService } from 'ngx-cookie-service';
import { ANDROID_APP_URL, IOS_APP_URL } from './../../app.component';

@Component({
    selector: 'app-install',
    templateUrl: './AppInstall.component.html',
    styleUrls: ['./AppInstall.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class AppInstallComponent implements OnInit {
    deviceInfo: DeviceInfo;
    appBannerAck: boolean = false;

    @Output()
    appBannerAckEvent = new EventEmitter<boolean>();

    constructor(private deviceService: DeviceDetectorService, private cookieService: CookieService) {
    }

    ngOnInit(): void {
        this.deviceInfo = this.deviceService.getDeviceInfo();
        this.appBannerAck = this.cookieService.get('bol_ack_app') == 'true';
    }

    appUrl(): String {
        if (this.deviceInfo.os == 'Android') {
            return ANDROID_APP_URL;
        } else if (this.deviceInfo.os == 'iOS') {
            return IOS_APP_URL;
        }

        return null;
    }

    dismissAppBanner(): void {
        this.cookieService.set('bol_ack_app', 'true', {expires: 30});
        this.appBannerAck = true;
        this.appBannerAckEvent.emit(true);
    }
}