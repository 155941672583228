
import { LocationModel } from './location';
import { Resource } from './resource';
import { SlotDate } from './SlotDate';

export class Slot {

  available: boolean;
  beforeTax: number;
  businessServiceId: number;
  businessServiceTypeId: number;
  companyName: string;
  count: number;
  date: string;
  dietaryRequirements:  string;
  email:  string;

  firstName: string;
  id: number;
  lastName: string;
  location: LocationModel;
  mobile: string;

  modeOfPayment: string;
  noOfAvailable: number;
  noOfBooked: number;
  noToBeBooked: number;
  price: number;
  propertyId: number;
  propertyReservationNumber: string;
  resource: Resource;

  slotDateList: SlotDate[];
  currency: string;
  frequency: string;

  finishDate: string;
  finishTime: string;
  startDate: string;
  startTime: string;

  tax: number;

  totalCapacity: number;
  duration: string;
  constructor()
      { }
}
