export class ServiceManagement {
    priority: string;
  enterStatus: string;
  createdBy: string;
  assignedTo: string;
  dateCreate: string;
  dateFixed: string;
  orderBookingNo: string;
  number: string;
  serviceRequestNo: string;
  name: string;
  description: string;
  resolution: string;
  comments: string;
  timeTakenToResolve: string;
  status: string;
  id:string;
}
