import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenStorage } from 'src/app/token.storage';
import { Location } from "@angular/common";

declare var Stripe: any;

declare var window: any;

@Component({
  selector: "app-OrderSucess",
  templateUrl: "./OrderSucess.component.html",
  styleUrls: ["./OrderSucess.component.scss"],
})
export class OrderSucessComponent implements OnInit {
  orderId: string;

  constructor(
    private token: TokenStorage,
    private route: ActivatedRoute, private router: Router,
    private locationBack: Location,
  ) {
   
    setTimeout(() => {
      this.goBack();
    }, 3000); 
  }
  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.orderId = params['orderId'];
    });
    console.log ("hjbh", this.orderId)
   
  }

  goBack(){
    this.locationBack.back();
  }
}
