import { Injectable } from '@angular/core';

export interface Menu {
  state: string;
  name: string;
  type?: string;
  children?: Menu[];
}

const MENUITEMS = [
  {
    state: '/',
    name: 'Home',
    type: 'link',
  },
  // {
  //   state: 'listing',
  //   // state: '/',
  //   name: 'Services',
  //   type: 'link',
  // },
  {
    state: 'about',
    name: 'About',
    type: 'link',
  },
   {
    state: 'team',
    name: 'Team',
    type: 'link',
  },
  {
    state: 'pricing',
    name: 'Pricing',
    type: 'link',
  },
  {
    // state: 'blog',
    state: 'posts',
    name: 'Blogs',
    type: 'link',
  },
  // {
  //   state: 'contact',
  //   name: 'Contact',
  //   type: 'link',
  // },
];

@Injectable()
export class MenuItems {
  getAll() {
    return MENUITEMS;
  }
}
