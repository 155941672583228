import { Routes } from "@angular/router";

import { AdminPanelLayoutComponent } from "./layouts/adminPanel/AdminPanelLayout.component";
import { FrontendPanelLayoutComponent } from "./layouts/frontendPanel/FrontendPanel.component";
import { AuthLayoutComponent } from "./layouts/auth/auth-layout.component";
import { OrderMenuLayoutComponent } from "./listing/order-menu-layout/order-menu-layout.component";
import { OrderSucessComponent } from "./listing/OrderSucess/OrderSucess.component";


export const AppRoutes: Routes = [
  //   {
  //   path: '',
  //   redirectTo: '',
  //   pathMatch: 'full',
  // },
  {
    path: "",
    component: FrontendPanelLayoutComponent,
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./home/home.module").then((m) => m.HomeModule),
      },

      {
        path: "grocery",
        loadChildren: () =>
          import("./shop/shop.module").then((m) => m.ShopModule),
      },

      {
        path: "listing",
        loadChildren: () =>
          import("./listing/listing.module").then((m) => m.ListingModule),
      },
      {
        path: "menu-order/:id",
        component:OrderMenuLayoutComponent,
      },
      {
        path: 'order-success',
        component: OrderSucessComponent
      },
      
    ],
  },
];
