import { Product } from './product';

export class ProductGroup {

  id: number;
  name: string;
  description: string;
  productCode: string;
  shortDescription: string;
  productDtoList: Product[];
  type: string;
  isSubGroup: boolean;
  printerName: string;


  constructor() {}
}
