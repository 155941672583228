import { OrderLineDto } from "./orderLineDto";
import { Product } from 'src/app/model/product';
import { Address } from 'src/app/model/address';
import { TaxDetails } from './TaxDetail/TaxDetails';

export class KOT {
  id: number;
  date: string;
  kotNo: string;
  priority: number;
  operatorName: string;
  orderLines: OrderLineDto[];
  propertyId: number;
  tableNo: string;
  time: string;
  orderNo: string;
  orderType: string;
  productGroupName: string;
  printerName: string;
  constructor() {}
}
