

export const PaidButOutOfStock_Status = 'Out Of Stock(Paid)';
export const OutOfStock_Status = 'Out Of Stock';
export const Available_Status = 'Available';

// kitchen status
export const Cooking_Status = 'Cooking';
export const ReadytoServe_Status = 'Ready to Serve';
export const ServedStatus = 'Served';
export const CompletedStatus = 'Completed';
