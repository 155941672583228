import { ServiceManagement } from './../model/service-management';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_URL } from '../app.component';

@Injectable({
  providedIn: 'root'
})
export class ServiceManagemntService {
  constructor(private http: HttpClient){

  }
  createServiceRequest(serviceManagement: ServiceManagement){
    return this.http.post<ServiceManagement>(API_URL + '/api/website/create', serviceManagement, { observe: 'response' });
  }
  
}
