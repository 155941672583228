import {
  Component,
  OnInit,
  ViewEncapsulation,
  ChangeDetectorRef,
  EventEmitter,
  Output,
  Inject,
  HostListener,
  ElementRef,
  ViewChild,
} from "@angular/core";

import { ServiceManagemntService } from "../../services/service-managemnt.service";
import { ServicesComponent } from "../../globalFrontendComponents/Services/Services.component";
import { ServiceManagement } from "../../model/service-management";
import { OrderDto } from 'src/app/model/orderDto';
import { Review } from "../../model/review";
import { ReviewService } from "../../services/review.service";
import { Logger } from "../../services/logger.service";
import { Slot } from "../../model/slot";
import { HotelBookingService } from "../../services/hotel-booking.service";

import { BusinessUser } from "src/app/model/user";

import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { DatePipe, Location } from "@angular/common";
import { ProductGroup } from "src/app/model/productGroup";
import { Product } from "src/app/model/product";
import { BusinessServiceDtoList } from "src/app/model/businessServiceDtoList";
import { ListingService } from "src/app/services/listing.service";
import { productVariationDtoList } from "src/app/model/productVariation";
import { AddToCart } from "src/app/model/addToCartDTO";
import { TokenStorage } from "src/app/token.storage";
import {
  NgbCalendar,
  NgbDate,
  NgbDateStruct,
  NgbInputDatepickerConfig,
  NgbDateParserFormatter,
} from "@ng-bootstrap/ng-bootstrap";
import { ServiceOpenDto } from "src/app/model/serviceOpenList";
import { SlotReservation } from "src/app/model/slotReservation";
import { ReservationServiceService } from "src/app/services/reservation-service.service";
import { SlotTiming } from "src/app/model/SlotTiming";
import { SlotDate } from "src/app/model/SlotDate";
import { DateService } from "src/app/services/date-service.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Room } from "src/app/model/room";
import { DateModel } from "src/app/model/dateModel";
import { Booking } from "src/app/model/booking";
import { Slots } from "src/app/model/slots";
import { BusinessServiceTypes } from "src/app/model/businessServiceTypes";
import { ResourceList } from "src/app/model/resourceList";
import { LocationList } from "src/app/model/locationList";
import { GoogleReview } from "src/app/model/googleReview";
import { BusinessServices } from "src/app/home/service/business-services.service";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { TriggerEventService } from "src/app/trigger-event.service";
import { Content } from '@angular/compiler/src/render3/r3_ast';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
const PRODUCT_CART = 'productCart';
export class Details {
  suburb: string;
  property: string;
  id: number;
}
// @() productRemoved = new EventEmitter();
export interface Time {
  value: string;
  viewValue: string;
}
declare var $: any;

@Component({
  selector: 'app-order-menu-layout',
  templateUrl: './order-menu-layout.component.html',
  styleUrls: ['./order-menu-layout.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [NgbInputDatepickerConfig],
})
export class OrderMenuLayoutComponent implements OnInit {
  @ViewChild('scrollContainer') scrollContainer: ElementRef | undefined;
  totalQuantity: number ;
  drawerOpen = false;
  openIndex: number | null = null;
  currentIndex = 0;
  itemsPerPage = 8;
  visibleMenus = [];
  visibleItems: any[] = [];
  selectedMenu: any = null;
  activeMenu: any = null;
  urlLocation: any;
  isCartModalOpen = false;
  filteredProductGroupsList: any[] = [];
  originalProductGroupsList: any[] = [];
  searchTerm: string = ''; 
  searchText: string = '';
  cards = [
    {
      title: 'Chicken Fried Rice',
      price: 'Rs. 100',
      image: 'https://www.foodiesfeed.com/wp-content/uploads/2023/06/burger-with-melted-cheese.jpg'
    },
    {
      title: 'Fried Chicken',
      price: 'Rs. 150',
      image: 'https://www.foodiesfeed.com/wp-content/uploads/2023/05/fried-chicken-commercial.jpg'
    },
    {
      title: 'Chicken Curry',
      price: 'Rs. 150',
      image: 'https://media.istockphoto.com/id/1285118162/photo/indian-chicken-curry-directly-above-photo.jpg?b=1&s=612x612&w=0&k=20&c=-44k3mJAIy6HF0umYot73543oneeaGaKYXvL8ysTe_Y='
    },
    {
      title: 'Butter Chicken',
      price: 'Rs. 200',
      image: 'https://media.istockphoto.com/id/1170729895/photo/indian-butter-chicken-horizontal-photo.jpg?b=1&s=612x612&w=0&k=20&c=JLiJNL46SxipOTPVsnof9a4d0HSNZ2Io7E7qd4AdPKM='
    },
    {
      title: 'Butter Chicken',
      price: 'Rs. 200',
      image: 'https://media.istockphoto.com/id/1170729895/photo/indian-butter-chicken-horizontal-photo.jpg?b=1&s=612x612&w=0&k=20&c=JLiJNL46SxipOTPVsnof9a4d0HSNZ2Io7E7qd4AdPKM='
    },

    {
      title: 'Mutton Liver Fry',
      price: 'Rs. 210',
      image: 'https://media.istockphoto.com/id/1089238566/photo/mutton-liver-fry-or-kaleji-masala-popular-non-vegetarian-recipe-from-india-and-pakistan.jpg?s=1024x1024&w=is&k=20&c=cVxYYfMXmKtn-lSUxG3D0neCVYmYFKJF8QZaWBpIJDo='
    },
  ]
  menus = [
    { name: 'Soups', cardimage: 'https://www.foodiesfeed.com/wp-content/uploads/2023/06/burger-with-melted-cheese.jpg' },
    { name: 'Salads', cardimage: 'https://www.foodiesfeed.com/wp-content/uploads/2023/06/burger-with-melted-cheese.jpg' },
    { name: 'Appetizers', cardimage: 'https://www.foodiesfeed.com/wp-content/uploads/2023/06/burger-with-melted-cheese.jpg' },
    { name: 'Asian', cardimage: 'https://www.foodiesfeed.com/wp-content/uploads/2023/06/burger-with-melted-cheese.jpg' },
    { name: 'Soups', cardimage: 'https://www.foodiesfeed.com/wp-content/uploads/2023/06/burger-with-melted-cheese.jpg' },
    { name: 'Salads', cardimage: 'https://www.foodiesfeed.com/wp-content/uploads/2023/06/burger-with-melted-cheese.jpg' },
    { name: 'Appetizers', cardimage: 'https://www.foodiesfeed.com/wp-content/uploads/2023/06/burger-with-melted-cheese.jpg' },
    { name: 'Asian', cardimage: 'https://www.foodiesfeed.com/wp-content/uploads/2023/06/burger-with-melted-cheese.jpg' },
    { name: 'Soups', cardimage: 'https://www.foodiesfeed.com/wp-content/uploads/2023/06/burger-with-melted-cheese.jpg' },
    { name: 'Salads', cardimage: 'https://www.foodiesfeed.com/wp-content/uploads/2023/06/burger-with-melted-cheese.jpg' },
  ];

  items = [
    { name: 'Soups', title: 'Salads', imgContent:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS1jWHxHhfiOWUiwYcaKSPQhy6cJW2kV0Hx7A&s", content: 'coconut chilli lime soup ' },
    { name: 'Soups', title: 'Soups', imgContent:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS1jWHxHhfiOWUiwYcaKSPQhy6cJW2kV0Hx7A&s",content: 'Soups chilli lime soup ' },
    { name: 'Soups', title: 'Appetizers',imgContent:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS1jWHxHhfiOWUiwYcaKSPQhy6cJW2kV0Hx7A&s", content: 'Appetizers chilli lime soup ' }
  ];

  itemsMenu = [
       { name: 'Asian',title:"junior classic minstrone soup" , price:"2132"},
       { name: 'Asian',title:"passta" , price:"454"},
       { name: 'Asian',title:"cheeze" , price:"2132"},
       { name: 'Asian',title:"icecreme" , price:"454"}
  ]

  itemsList = [
    { name: 'Salads',title:"junior classic minstrone soup" , price:"2132"},
    { name: 'Salads',title:"passta" , price:"454"},
    { name: 'Salads',title:"cheeze" , price:"2132"},
    { name: 'Salads',title:"icecreme" , price:"454"}
]
contentDialog: any;
productGroup: ProductGroup;
businessServices: BusinessServiceDtoList[];
businessService: BusinessServiceDtoList;
count:number;
// businessServicesForProducts: BusinessServiceDtoList[];
// businessServiceForProducts: BusinessServiceDtoList;
isBookOneLocal: boolean = true;
businessServiceDto: BusinessServiceDtoList;
productGroups: ProductGroup[];
productGroupsList = [];
filteredProductGroups = [];
proVariationDtoLists: productVariationDtoList[];
proVariationDtoList: productVariationDtoList;
branchList: any;
name:string;
products: Product[];
product: Product;
orderDto: OrderDto;
addToCart: AddToCart;
plans: any[] = [];
quantityProductItem:number =1;
slotReservation: SlotReservation;
businessTypeName: string;
productGroupList:any[];
totalPrice = 0;
quantity = 1;
delivertDateAndTime: string;
loaderSlot = false;
totalPriceVariation = 0;
quantityVariation = 0;
selectOrder = false;
selectBooking = false;
selectHotelBooking = false;

planDetails: any;
quantityItem:any;
businessUser: BusinessUser;
resourceName: string = "";
locationName: string = "";
locationLabel: string = "Location";
resourceLabel: string = "Resource";
selectedIndex: number = -1;
toppingId:number;
extrasId:number;
data: any = [];
details: Details;
selectedServiceName: string;
lat = 0;
lng = 0;

smallGalleryTitle = "Gallery";
smallGalleryData: any = [
  {
    image: "assets/images/dp-1.jpg",
  },
  {
    image: "assets/images/dp-4.jpg",
  },
];
timeList: Time[] = [
  { value: "06:00", viewValue: "06:00 AM" },
  { value: "06:30", viewValue: "06:30 AM" },
  { value: "07:00", viewValue: "07:00 AM" },
  { value: "07:30", viewValue: "07:30 AM" },
  { value: "08:00", viewValue: "08:00 AM" },
  { value: "08:30", viewValue: "08:30 AM" },
  { value: "09:00", viewValue: "09:00 AM" },
  { value: "09:30", viewValue: "09:30 AM" },
  { value: "10:00", viewValue: "10:00 AM" },
  { value: "10:30", viewValue: "10:30 AM" },
  { value: "11:00", viewValue: "11:00 AM" },
  { value: "11:30", viewValue: "11:30 AM" },
  { value: "12:00", viewValue: "12:00 PM" },
  { value: "12:30", viewValue: "12:30 PM" },
  { value: "13:00", viewValue: "01:00 PM" },
  { value: "13:30", viewValue: "01:30 PM" },
  { value: "14:00", viewValue: "02:00 PM" },
  { value: "14:30", viewValue: "02:30 PM" },
  { value: "15:00", viewValue: "03:00 PM" },
  { value: "15:30", viewValue: "03:30 PM" },
  { value: "16:00", viewValue: "04:00 PM" },
  { value: "16:30", viewValue: "04:30 PM" },
  { value: "17:00", viewValue: "05:00 PM" },
  { value: "17:30", viewValue: "05:30 PM" },
  { value: "18:00", viewValue: "06:00 PM" },
  { value: "18:30", viewValue: "06:30 PM" },
  { value: "19:00", viewValue: "07:00 PM" },
  { value: "19:30", viewValue: "07:30 PM" },
  { value: "20:00", viewValue: "08:00 PM" },
  { value: "20:30", viewValue: "08:30 PM" },
  { value: "21:00", viewValue: "09:00 PM" },
  { value: "21:30", viewValue: "09:30 PM" },
  { value: "22:00", viewValue: "10:00 PM" },
  { value: "22:30", viewValue: "10:30 PM" },
  { value: "23:00", viewValue: "11:00 PM" },
  { value: "23:30", viewValue: "11:30 PM" },
  { value: "00:00", viewValue: "12:00 AM" },
  { value: "00:30", viewValue: "12:30 AM" },
  { value: "01:00", viewValue: "01:00 AM" },
  { value: "01:30", viewValue: "01:30 AM" },
  { value: "02:00", viewValue: "02:00 AM" },
  { value: "02:30", viewValue: "02:30 AM" },
  { value: "03:00", viewValue: "03:00 AM" },
  { value: "03:30", viewValue: "03:30 AM" },
  { value: "04:00", viewValue: "04:00 AM" },
  { value: "04:30", viewValue: "04:30 AM" },
  { value: "05:00", viewValue: "05:00 AM" },
  { value: "05:30", viewValue: "05:30 AM" },
];
serviceString: any;
loader: boolean;

serviceSelected: any;

prepareDay = 0;
prepareHour = 0;
prepareMinute = 0;

leadMaxDay = 0;
leadMaxMin = 0;
leadMaxHour = 0;

addToCartList: AddToCart[];
serviceOpenDto: ServiceOpenDto;

isMenuItemAdded = false;
containerActive: boolean;
isMenuItemAbout = true;
isReservationList = false;

selectService: any;
cartProductList = [];
slotDateLists: any[];
slotLocationLists: any[];
slotResourceLists: any[];
slotResource: string;
slotLocation: string;
slotTimeIndex: any;

ngbDate: NgbDateStruct;
enabledDates: NgbDateStruct[];
leadHour: number;
leadDay: number;
leadMin: number;
today: any;
fromEmail:string;
phone:number;
message:string;
isDisabled: any;
slotTiming: SlotTiming;
slotDateList: SlotDate[];
slotDateListss: SlotDate[];
slotDateFilter: SlotDate[];
slot: Slot;
slots: Slots;
subject:string;



slotByDate: Slot;
searchDate: string;
slotTimingFromTimeString: string;
slotTimingToTimeString: string;
topping:any[] = [];
extras:any[] = [];
businessServiceTypesCart: BusinessServiceTypes;
businessServiceTypesCartList: BusinessServiceTypes[];

//....
resourceArrayList: ResourceList[] = [];
resource: ResourceList;

bookedTiming: SlotTiming;
location: LocationList;

reservationFromDate: any;
reservationToDate: any;

slotTimes: SlotTiming[];
slotTime: SlotTiming;

rooms: Room[];
availableRooms: any[];
roomAvailability = false;

dateModel: DateModel;

daySelected: string;
yearSelected: string;
monthSelected: number;

daySelected2: string;
yearSelected2: string;
monthSelected2: number;

currentDay: string;

monthArray = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

dateSelected = false;
locationSelected = false;
resourceSelected = false;

// slider config
slideConfig = {
  slidesToShow: 4, // Show 4 slides on desktop
  slidesToScroll: 1, // Scroll one slide at a time
  arrows: true, // Enable arrows for navigation
  dots: true, // Show dots for navigation
  infinite: true, // Infinite loop for sliding
  autoplay: false, // Disable autoplay
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3, // Show 3 slides on tablets
        slidesToScroll: 1,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1, // Show 1 slide on mobile
        slidesToScroll: 1
      }
    }
  ]
};


isDesktop: boolean = true;

places: any = [
  {
    image: "assets/images/most-img-4.jpg",
  },
];

hoveredDate: NgbDate | null = null;

fromDate: NgbDate | null;
toDate: NgbDate | null;
orderNow = true;
dateFromDate: string;
dateToDate: string;
booking: Booking;
loaderHotelBooking = false;
checkAvailabilityStatusHide = true;
selectedRoomName =
  'Not Selected, Please choose a room type from "Rooms" menu';
checkAvailabilityStatus = false;
checkAvailabilityStatusName: string;
selectedRoomMaximumOccupancy: number;
selectedRoomAvailableNumber: number;
slotSelected: any;
resourceSelectedList: any[];
isWorkingTime: boolean = true;
display = true;
taxPercentage: number;

isReviewFound: boolean = false;
public activePillIndex:number = 0;

googleReviews: GoogleReview[];
rate: number;
slortResource: any;
slotSelected2: Slots;
priority:string;
slotCount: number = 0;
bookingTimeListLength: any[];
availabilityNumber: number = 0;
offersList: any[] = [];

showAlert: boolean = false;
alertType: string;
isSuccess: boolean;
headerTitle: string;
bodyMessage: string;
hasPlan: boolean = false;
customerReviews: Review[];

isCustomerReviewFound: boolean = false;
currentRate = 4.54;

closeResult = "";
modalImage = "";
modalTitle = "";
public selectedIndex1 = 0; 
orderNowLevelText: string = "o";
isBookingOffering: boolean = true;
addToCartText: string;
serviceTitle: string;
showHideProperty:boolean;
slotCountTitle: string;
serviceTypeFilterData: any;
bookingMinDate: NgbDate | null;
bookingMaxDate: NgbDate | null;
offerValid = false;
quantityProductt=0;
offerDiscount = 0;
businessServiceId: number;
productRemoved: any;
serviceDetailsData: ServiceManagement;
Priority: FormControl = new FormControl();
EnterStatus: FormControl = new FormControl();
CreatedBy: FormControl = new FormControl();
AssignedTo: FormControl = new FormControl();
DateCreate: FormControl = new FormControl();
DateFixed: FormControl = new FormControl();
OrderBookingNo: FormControl = new FormControl();
Number: FormControl = new FormControl();
ServiceRequestNo: FormControl = new FormControl();
Name: FormControl = new FormControl();
Description: FormControl = new FormControl();
Resolution: FormControl = new FormControl();
EnterComments: FormControl = new FormControl();
TimeTakenToResolve: FormControl = new FormControl();
CheckedInDateControll: FormControl = new FormControl();
BookingDateControll: FormControl = new FormControl();
ServiceStatusControll: FormControl = new FormControl();

BookingStatusControll: FormControl = new FormControl();

serviceDetailsForm: FormGroup = new FormGroup({
  priority: this.Priority,
  enterStatus: this.EnterStatus,
  createdBy: this.CreatedBy,
  assignedTo: this.AssignedTo,
  dateCreate: this.DateCreate,
  dateFixed: this.DateFixed,
  orderBookingNo: this.OrderBookingNo,
  number: this.Number,
  serviceRequestNo: this.ServiceRequestNo,
  name: this.Name,
  description: this.Description,
  resolution: this.Resolution,
  enterComments: this.EnterComments,
  timeTakenToResolve: this.TimeTakenToResolve,
  checkedInDateControll: this.CheckedInDateControll,
  serviceStatusControll: this.ServiceStatusControll,
  bookingDateControll: this.BookingDateControll,
});


  constructor(
    private acRoute : ActivatedRoute,
    private serviceManagementService : ServiceManagemntService,
    private token: TokenStorage,
    private TriggerEventService: TriggerEventService,
    private listingService: ListingService,
    private reviewService: ReviewService,
    private hotelBookingService: HotelBookingService,
    private offerService: BusinessServices,
    private changeDetectorRefs: ChangeDetectorRef,
    private router: Router,
    public datepipe: DatePipe,
    private locationBack: Location,
    private dateService: DateService,
    private reservationService: ReservationServiceService,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    private modalService: NgbModal,
    private cdr: ChangeDetectorRef
  ) {
    this.acRoute.queryParams.subscribe((params) => {
      // if (params["id"] !== undefined) {
     
      //   this.urlLocation = params["id"];
      
      // }
      let id = this.acRoute.snapshot.params['id'];

    });
    this.serviceDetailsData = new ServiceManagement();
    this.businessServiceTypesCart = new BusinessServiceTypes();
    this.businessServiceDto = new BusinessServiceDtoList();
    this.businessService = new BusinessServiceDtoList();
    this.productGroup = new ProductGroup();
    this.businessUser = new BusinessUser();
    this.details = new Details();
    this.orderDto = new OrderDto();
    this.slotReservation = new SlotReservation();
    this.slotTime = new SlotTiming();

    this.slotTiming = new SlotTiming();
    this.slot = new Slot();
    this.booking = new Booking();
    this.bookingMinDate = calendar.getToday();

    let businessSlug = this.acRoute.snapshot.params['id'];
    if (businessSlug !== undefined) {
      this.data = businessSlug;
      this.details = this.data;
   
      // Logger.log("details: " + JSON.stringify(businessSlug));

      if (this.data.id == undefined) {
        this.getPropertyDetailsBySeoName(this.data);
      } else {
        this.getPropertyDetails(this.details.id);
      }

      this.addToCartList = [];
      this.slotTimes = [];
    }
    this.acRoute.queryParams.subscribe((params) => {
      if (params["BookingEngine"] !== undefined) {
       
        this.urlLocation = params["BookingEngine"];
    

      }
    });
    if (
      this.token.getProductCart() != null ||
      this.token.getProductCart() != undefined
    ) {
      this.addToCartList = this.token.getProductCart();
      this.isMenuItemAdded = true;
      this.isMenuItemAbout = false;
    }
    if (
      this.token.getSlotBookData() != null ||
      this.token.getSlotBookData() != undefined
    ) {
      this.slotReservation = this.token.getSlotBookData();
      // this.isMenuItemAdded = true;
      // this.isMenuItemAbout = false;
    }
    
    // this.slotReservation = this.token.getSlotBookData();

    // this.fromDate = calendar.getToday();
   }


  ngOnInit(): void {
    this.updateVisibleMenus();
    this.checkScreenSize();
    this.visibleItems = this.productGroupsList; // Default view when no tab is selected
    this.originalProductGroupsList = this.productGroupsList;
    this.filteredProductGroupsList = this.productGroupsList;
  
    if(this.token.getProductCart()?.length > 0){
      
      this.orderQuantity(this.products);
    }
    this.menuClick();
    this.serviceDetailsData.priority ="";
    this.serviceDetailsData.status ="";
        this.selectedIndex=this.productGroupsList[0]?.productGroup[0];
    // this.orderDto.productDtoList = this.addToCartList;
    $(".listing-slider-small").slick({
      centerMode: true,
      centerPadding: "0",
      slidesToShow: 3,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    });

    $(".mfp-gallery-container").each(function () {
      // the containers for all your galleries

      $(this).magnificPopup({
        type: "image",
        delegate: "a.mfp-gallery",

        fixedContentPos: true,
        fixedBgPos: true,

        overflowY: "auto",

        closeBtnInside: false,
        preloader: true,

        removalDelay: 0,
        mainClass: "mfp-fade",

        gallery: { enabled: true, tCounter: "" },
      });
    });
  }

  
  public selectCard(index: number): void {
    this.selectedIndex1 = index;
    this.closeDrawer();

  }
  isDesktopView(): boolean {
    return this.isDesktop;
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.checkScreenSize();
  }
  filterProducts() {
    // Log the current search term
    
    
    if (this.searchTerm) {
      this.filteredProductGroupsList = this.originalProductGroupsList.map(group => {
        // Log the group being processed
       
        
        if (!group || !Array.isArray(group.productDtoList)) {
          console.error('Invalid group or productDtoList:', group);
          return group; // Return the original group if invalid
        }
  
        // Filter products based on the search term
        const filteredProducts = group.productDtoList.filter(product => {
          // Ensure the product and its name are defined
          if (product && product.name) {
            return product.name.toLowerCase().includes(this.searchTerm.toLowerCase());
          }
          return false; // Exclude undefined products
        });
  
        // Log the filtered products for the current group
        
  
        return {
          ...group,
          productDtoList: filteredProducts // Return the group with the filtered product list
        };
      }).filter(group => group.productDtoList.length > 0); // Exclude groups with no products
    } else {
      // Reset to the original list if no search term is provided
    
      this.filteredProductGroupsList = this.originalProductGroupsList;
    }
  }
  
  
  // Helper function to check and update the screen size
  checkScreenSize(): void {
    this.isDesktop = window.innerWidth >= 1024;
  }

  scrollLeft(container: HTMLElement) {
    container.scrollBy({ left: -200, behavior: 'smooth' });
  }

  scrollRight(container: HTMLElement) {
    container.scrollBy({ left: 200, behavior: 'smooth' });
  }

  // Method triggered when a product group is clicked
  onMenuClick(group: any): void {
    this.selectedMenu = group;
    this.activeMenu = group;
    this.closeDrawer();
    // Update visibleItems based on the selected menu
    // if (group.name === 'Soups') {
    //   this.visibleItems = this.items;
    // } else if (group.name === 'Asian') {
    //   this.visibleItems = this.itemsMenu;
    // } else {
    //   this.visibleItems = []; 
    // }
 
    // Add your logic to handle the menu click event here
  }

  


  // Method to check if a group is selected
  isSelected(group: any): boolean {
    // Add logic here if needed to handle selected state
    return this.selectedMenu === group;
    // return false;
  }
  
  toggleDrawer() {
    this.drawerOpen = !this.drawerOpen;
  }

  closeDrawer() {
    this.drawerOpen = false;
  }

  updateVisibleMenus() {
    this.visibleMenus = this.productGroupsList.slice(this.currentIndex, this.currentIndex + this.itemsPerPage);
  }

  previous() {
    if (this.currentIndex > 0) {
      this.currentIndex -= this.itemsPerPage;
    } else {
      this.currentIndex = this.productGroupsList.length - this.itemsPerPage; // Loop to the last set of items
    }
    this.updateVisibleMenus();
  }

  next() {
    if (this.currentIndex + this.itemsPerPage < this.productGroupsList.length) {
      this.currentIndex += this.itemsPerPage;
    } else {
      this.currentIndex = 0; // Loop to the first set of items
    }
    this.updateVisibleMenus();
  }

  isPreviousDisabled(): boolean {
    return this.currentIndex === 0;
  }

  isNextDisabled(): boolean {
    return this.currentIndex + this.itemsPerPage >= this.productGroupsList.length;
  }

isProductInCart(product: any): boolean {
  return this.addToCartList.some(item => item.id === product.id);
}

isVariationInCart(item: any): boolean {
  return this.addToCartList.some(cartItem => {
    return cartItem.productCode === item.code || 
           (cartItem.variationDtoList && cartItem.variationDtoList.some(variation => variation.code === item.code));
  });
}
  


 
  // Check if the menu is active
  isActive(index: number): boolean {
    return this.selectedIndex1 === index;
  }
 toggle(index: number) {
    this.openIndex = this.openIndex === index ? null : index;
  }

  offeringMethodChange() {
    this.slotReservation = new SlotReservation();
    this.slotTimeIndex = undefined;
    this.isReservationList = false;
    this.dateSelected = false;
    this.slots = new Slots();
    this.resourceArrayList = [];

    this.slotRevdata(this.businessServiceDto);
  }

  ngAfterViewInit() {
    $("#myCollapsible").collapse({
      toggle: true,
    });
    this.offeringMethodChange();
  }
  open(content, src, title) {
    this.modalImage = src;
    this.modalTitle = title;
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
  onOfferDetails(id) {
    this.router.navigate(["/offer-list/" + id]);
  }
  getOfferList(seo) {
    this.offerService
      .getOfferListFindBySeoFriendlyName(seo)
      .subscribe((data) => {
        this.offersList = data.body;
        Logger.log("offersList: " + JSON.stringify(this.offersList));
        this.offersList.forEach((offer) => {
          let currentDate = new Date().getTime();
          if (offer.startDate <= currentDate && currentDate <= offer) {
            this.offerValid = true;
            this.offerDiscount = offer.discountPercentage;
            this.orderDto.discountPercentage = offer.discountPercentage;
          }
        });
      });
  }
  scrollToProducts() {
    if (this.scrollContainer) {
      this.scrollContainer.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
  
  orderQuantity(products){

    if(this.addToCartList?.length >0){
  
      products.unitsInOrder =1;
   
    }else{
      products.unitsInOrder =0;
    }
  }
  submitForm(){


    this.serviceManagementService.createServiceRequest(this.serviceDetailsData).subscribe(
      (res) => {
        this.serviceDetailsForm.reset();
        this.changeDetectorRefs.detectChanges();
        // this.dialogRef.close(this.serviceDetailsData);
      },
     
    );
  }
  mileSecondToNGBDate(date: string) {
    const dsd = new Date(date);
    const year = dsd.getFullYear();
    const day = dsd.getDate();
    const month = dsd.getMonth() + 1;
    return { year: year, month: month, day: day };
  }

  openCartModal() {
    this.isCartModalOpen = true;
  }

  // Close the modal
  closeCartModal() {
    this.isCartModalOpen = false;
  }
  

  getCustomerReview(id) {
    this.loader = true;
    this.reviewService
      .getReviewFindByPropertyIdAndReviewStatus(id, "Approved")
      .subscribe(
        (data) => {
          this.customerReviews = data.body;

          if (this.customerReviews.length > 0) {
            this.isCustomerReviewFound = true;
          } else {
            this.isCustomerReviewFound = false;
          }
          this.loader = false;
          this.changeDetectorRefs.detectChanges();
        },
        (error) => {
          this.loader = false;
          this.isCustomerReviewFound = false;
          this.changeDetectorRefs.detectChanges();
        }
      );
  }
  reservationDateSet(startDate, endDate) {
    this.reservationFromDate = this.calendar.getToday();
    this.reservationToDate = this.mileSecondToNGBDate(endDate);
    // Logger.log(
    //   "this.reservationFromDate:" + JSON.stringify(this.reservationFromDate)
    // );
    // Logger.log(
    //   "this.reservationToDate:" + JSON.stringify(this.reservationToDate)
    // );
  }
  getBranch(id) {
    this.listingService.getBusinessBranch(id).subscribe((response) => {
      this.branchList = response.body;
      // Logger.log('this.branchList:' + JSON.stringify(this.branchList));
    });
  }
  selectToday() {
    this.ngbDate = this.calendar.getToday();
    this.slotReservation.date = this.getDateFormatDayMonthYear(
      this.ngbDate.day,
      this.ngbDate.month,
      this.ngbDate.year
    );
    this.searchDate = this.getDateFormatDayMonthYear(
      this.ngbDate.day,
      this.ngbDate.month,
      this.ngbDate.year
    );
  }
  setdate() {
    this.slotReservation.date = this.getDateFormatDayMonthYear(
      this.ngbDate.day,
      this.ngbDate.month,
      this.ngbDate.year
    );
    this.searchDate = this.getDateFormatDayMonthYear(
      this.ngbDate.day,
      this.ngbDate.month,
      this.ngbDate.year
    );
    this.getResarvationList(this.searchDate);
    // this.applySlotDateFilter(this.searchDate);

    this.slotResourceLists = [];
    this.slotLocationLists = [];
    this.resourceArrayList = [];

    this.dateSelected = true;
    this.slotLocation = "";
  }
  getDateFormatYearMonthDay(
    day12: number,
    month12: number,
    year12: number
  ): string {
    const year = year12;
    const date = day12;

    const month = month12;

    let month1;
    let day1;
    if (Number(month) < 10) {
      month1 = `0${month}`;
    } else {
      month1 = `${month}`;
    }
    if (Number(date) < 10) {
      day1 = `0${date}`;
    } else {
      day1 = `${date}`;
    }

    return `${year}-${month1}-${day1}`;
  }
  getDateFormatDayMonthYear(
    day12: number,
    month12: number,
    year12: number
  ): string {
    const year = year12;
    const date = day12;

    const month = month12;

    let month1;
    let day1;
    if (Number(month) < 10) {
      month1 = `0${month}`;
    } else {
      month1 = `${month}`;
    }
    if (Number(date) < 10) {
      day1 = `0${date}`;
    } else {
      day1 = `${date}`;
    }

    return `${day1}-${month1}-${year}`;
  }



  setLocation() {
    this.applySlotLocationFilter(this.searchDate, this.slotLocation);
    this.slotResourceLists = [];

    for (let i = 0; i < this.slotDateFilter.length; i++) {
      if (
        this.slotResourceLists.indexOf(
          this.slotDateFilter[i].resource.resourceName
        ) == -1
      ) {
        this.slotResourceLists.push(
          this.slotDateFilter[i].resource.resourceName
        );
      }
    }
    this.locationSelected = true;
    this.slotResource = "";
  }

  setSlotTime2(slotTimeIndex, resourceIndex: number, resource: any, i: any) {
    // Logger.log("i 2" + this.checkAvailability(resource.name, slotTimeIndex));
    if (
      this.checkAvailability(resource.name, slotTimeIndex) !=
      slotTimeIndex.slotAvailabilityDto.noOfAvailable
    ) {
      this.setSlotTime(slotTimeIndex, resourceIndex, resource);
    } else {
      // Logger.log(" not available");
      this.showMessage();
    }
  }

  checkAvailability(resourceName: string, bookingTime: any) {
    this.availabilityNumber = 0;

    if (
      this.slotReservation.businessServiceTypes != undefined &&
      this.slotReservation.businessServiceTypes.some(
        (p) => p.id === this.businessServiceTypesCart.id
      ) === true
    ) {
      this.serviceSelected = this.slotReservation.businessServiceTypes.find(
        (cart) => cart.id === this.businessServiceTypesCart.id
      );
      // Logger.log(
      //   " service  true" +
      //     this.dateService.convertMillisecondsToYYYMMDDFormat(this.slots.date)
      // );
      if (
        this.slotReservation.businessServiceTypes[
          this.slotReservation.businessServiceTypes.indexOf(
            this.serviceSelected
          )
        ].slots.some(
          (s) =>
            s.date ===
            this.dateService.convertMillisecondsToYYYMMDDFormat(this.slots.date)
        ) === true
      ) {
        this.slotSelected = this.slotReservation.businessServiceTypes[
          this.slotReservation.businessServiceTypes.indexOf(
            this.serviceSelected
          )
        ].slots.find(
          (slot) =>
            slot.date ===
            this.dateService.convertMillisecondsToYYYMMDDFormat(this.slots.date)
        );

        this.resourceSelectedList = this.slotSelected.resourceList;
        // Logger.log(" date true" + JSON.stringify(this.resourceSelectedList));

        if (
          this.resourceSelectedList.some(
            (r) => r.name === this.resourceName
          ) === true
        ) {
          this.slortResource = this.resourceSelectedList.find(
            (res) => res.name === this.resourceName
          );

          //  Logger.log("this.slortResource" + JSON.stringify(this.slortResource));

          if (
            this.slortResource.bookedTimings.some(
              (bt) =>
                bt.startTime === bookingTime.startTime &&
                bt.finishTime === bookingTime.finishTime
            ) === true
          ) {
            this.bookingTimeListLength =
              this.slortResource.bookedTimings.filter((item) => {
                const searchResult =
                  item.startTime != null &&
                  item.startTime
                    .trim()
                    .indexOf(bookingTime.startTime.trim().trim()) > -1 &&
                  item.finishTime != null &&
                  item.finishTime
                    .trim()
                    .indexOf(bookingTime.finishTime.trim()) > -1;

                return searchResult;
              });

            this.availabilityNumber = this.bookingTimeListLength.length;

            return this.availabilityNumber;
            // Logger.log(
            //   "this.bookingTimeListLength" + this.bookingTimeListLength.length
            // );
          } else {
            return this.availabilityNumber;
          }
        } else {
          return this.availabilityNumber;
        }
      } else {
        return this.availabilityNumber;
      }
    } else {
      return this.availabilityNumber;
    }
  }

  setSlotTime(slotTimeIndex, resourceIndex: number, resource: any) {
    this.bookedTiming = new SlotTiming();
    this.bookedTiming = slotTimeIndex;

    this.location = new LocationList();
    if (resource.locationList.length > 0) {
      this.location = resource.locationList[0];
    }

    this.resource = new ResourceList();
    this.resource.name = resource.name;
    this.resource.desc = resource.desc;
    this.resource.imageUrl = resource.imageUrl;

    if (
      this.resourceArrayList.some((data) => data.name === resource.name) ===
      true
    ) {
      // Logger.log(
      //   "exist" +
      //     this.resourceArrayList.map((item) => item.name).indexOf(resource.name)
      // );
      let resourceIndexNumber = this.resourceArrayList
        .map((item) => item.name)
        .indexOf(resource.name);
      Logger.log(
        "index" +
          this.resourceArrayList[resourceIndexNumber].bookedTimings
            .map((time) => time.startTime)
            .indexOf(this.bookedTiming.startTime)
      );
      let timeSlotIndex = this.resourceArrayList[
        resourceIndexNumber
      ].bookedTimings
        .map((time) => time.startTime)
        .indexOf(this.bookedTiming.startTime);

      if (timeSlotIndex === -1) {
        this.resourceArrayList[resourceIndexNumber].bookedTimings.push(
          this.bookedTiming
        );

        Logger.log(" new time -1");
      } else {
        Logger.log(" exist time");

        this.resourceArrayList[resourceIndexNumber].bookedTimings.splice(
          timeSlotIndex,
          1
        );

        if (
          this.resourceArrayList[resourceIndexNumber].bookedTimings.length === 0
        ) {
          this.resourceArrayList.splice(resourceIndexNumber, 1);
        }
      }
    } else {
      Logger.log("exist not");
      this.resource.bookedTimings = [];
      this.resource.bookedTimings.push(this.bookedTiming);

      this.resource.locationList = [];
      this.resource.locationList.push(this.location);
      this.resourceArrayList.push(this.resource);
    }

    this.slotTime = slotTimeIndex;
    // Logger.log(JSON.stringify(resource)+'this.slotTime ' + JSON.stringify(this.slotTime));
    this.setResource(resourceIndex);

    //  Logger.log(JSON.stringify(this.resourceArrayList));

    this.slotTimeIndex = slotTimeIndex;
  }

  setResource(slotResourceIndex) {
    // Logger.log('search slotResource ' + JSON.stringify(this.slotResource));
    // this.applySlotFilter(this.searchDate, this.slotResource, this.slotLocation);

    Logger.log(JSON.stringify(slotResourceIndex));
    if (this.slots.resourceList[slotResourceIndex].locationList?.length) {
      this.locationName =
        this.slots.resourceList[slotResourceIndex].locationList[0].name;
      this.resourceName = this.slots.resourceList[slotResourceIndex].name;
    }

    this.slotTimes = [];
    Logger.log("this.locationName ", JSON.stringify(this.locationName));
    this.slotTimes =
      this.slots.resourceList[slotResourceIndex].availableTimings;
    // for (let i = 0; i < this.slotDateFilter.length; i++) {
    //   this.slotTimes = this.slotDateFilter[i].slotTimingDtos;
    // }
    this.resourceSelected = true;
    // this.slotTimeIndex = '';
  }
  backClicked() {
    this.locationBack.back();
  }
  selectedService(value, type: any) {
    if (type === "book") {
      this.serviceTitle = "Booking";
      this.addToCartText = "Reserve";
      this.slotCountTitle = "Reservations";
      this.slotReservation.isBookable = true;
    } else if (type === "enquiry") {
      this.serviceTitle = "Enquiry";
      this.addToCartText = "Enquiry";
      this.slotCountTitle = "Enquiries";
      this.slotReservation.isBookable = false;
    }

    this.ngbDate = undefined;
    this.slotReservation.date = undefined;

    this.selectedServiceName = value.name;
    this.businessServiceTypesCart = new BusinessServiceTypes();
    this.businessServiceTypesCart.id = value.id;
    this.businessServiceTypesCart.name = value.name;
    this.businessServiceTypesCart.businessTermResource =
      value.businessTermResource;
    this.businessServiceTypesCart.businessTermLocation =
      value.businessTermLocation;
    this.businessServiceTypesCart.capacityPerSlot = value.capacityPerSlot;
    this.businessServiceTypesCart.description = value.description;
    this.businessServiceTypesCart.slotPricingDto = value.slotPricingDto;
    this.businessServiceTypesCart.businessServiceId = value.businessServiceId;
    this.businessServiceTypesCart.durationInMinutes = value.durationInMinutes;
    this.isReservationList = true;
    // this.getResarvationList();
  }
  addBookingToCart() {
    this.slots.date = this.slotReservation.date;
    // this.slots.duration = this.slotTime.duration;
    this.slots.resourceList = this.resourceArrayList;

    this.businessServiceTypesCart.slots = [];
    this.businessServiceTypesCart.slots.push(this.slots);

    if (
      this.slotReservation.businessServiceTypes != undefined &&
      this.slotReservation.businessServiceTypes.some(
        (p) => p.id === this.businessServiceTypesCart.id
      ) === true
    ) {
      Logger.log(" exixting");
      this.serviceSelected = this.slotReservation.businessServiceTypes.find(
        (cart) => cart.id === this.businessServiceTypesCart.id
      );

      if (
        this.slotReservation.businessServiceTypes[
          this.slotReservation.businessServiceTypes.indexOf(
            this.serviceSelected
          )
        ].slots.some((s) => s.date === this.slots.date) === true
      ) {
        Logger.log(" exixting date");
        this.slotSelected = this.slotReservation.businessServiceTypes[
          this.slotReservation.businessServiceTypes.indexOf(
            this.serviceSelected
          )
        ].slots.find((slot) => slot.date === this.slots.date);

        this.resourceSelectedList = this.slotSelected.resourceList;

        for (let i = 0; i < this.resourceArrayList.length; i++) {
          if (
            this.resourceSelectedList.some(
              (r) => r.name === this.resourceArrayList[i].name
            ) === true
          ) {
            this.slortResource = this.resourceSelectedList.find(
              (res) => res.name === this.resourceArrayList[i].name
            );
            this.slotSelected2 =
              this.slotReservation.businessServiceTypes[
                this.slotReservation.businessServiceTypes.indexOf(
                  this.serviceSelected
                )
              ].slots[
                this.slotReservation.businessServiceTypes[
                  this.slotReservation.businessServiceTypes.indexOf(
                    this.serviceSelected
                  )
                ].slots.indexOf(this.slotSelected)
              ];
            for (
              let j = 0;
              j < this.resourceArrayList[i].bookedTimings.length;
              j++
            ) {
              // need to add a check for availability here
              this.slotSelected2.resourceList[
                this.slotSelected2.resourceList.indexOf(this.slortResource)
              ].bookedTimings.push(this.resourceArrayList[i].bookedTimings[j]);
            }
          } else {
            this.slotReservation.businessServiceTypes[
              this.slotReservation.businessServiceTypes.indexOf(
                this.serviceSelected
              )
            ].slots[
              this.slotReservation.businessServiceTypes[
                this.slotReservation.businessServiceTypes.indexOf(
                  this.serviceSelected
                )
              ].slots.indexOf(this.slotSelected)
            ].resourceList.push(this.resourceArrayList[i]);
          }
        }
        // this.slotReservation.businessServiceTypes[this.slotReservation.businessServiceTypes.indexOf(this.serviceSelected)].slots[
        //   this.slotReservation.businessServiceTypes[this.slotReservation.businessServiceTypes.indexOf(this.serviceSelected)].slots.indexOf( this.slotSelected)
        // ].resourceList = this.resourceArrayList;
      } else {
        Logger.log(" exixting date not");
        this.slotReservation.businessServiceTypes[
          this.slotReservation.businessServiceTypes.indexOf(
            this.serviceSelected
          )
        ].slots.push(this.slots);
      }
    } else {
      Logger.log(" not exixting");
      if (this.slotReservation.businessServiceTypes === undefined) {
        this.slotReservation.businessServiceTypes = [];
        this.slotReservation.businessServiceTypes.push(
          this.businessServiceTypesCart
        );
      } else {
        this.slotReservation.businessServiceTypes.push(
          this.businessServiceTypesCart
        );
      }
    }

    this.slotReservation.propertyId = this.businessUser.id;
    this.slotReservation.businessName = this.businessUser.businessName;
    this.slotReservation.resourceName = this.resourceName;
    this.slotReservation.locationName = this.locationName;
    this.slotReservation.bookingStatus = "NEW";
    this.slotReservation.businessTypeName = this.businessUser.businessType;
    this.slotReservation.currency = this.businessUser.localCurrency;


    this.selectService = undefined;
    this.isReservationList = false;
    this.ngbDate = undefined;
    this.dateSelected = false;
    this.resourceSelected = false;
    this.slotTimeIndex = undefined;
    this.businessServiceTypesCart = undefined;

    this.priceCalculate();
  }
  removeReservationSlot(resourceList) {
    this.checkSlotReservationList();
    this.priceCalculate();
    // Logger.log(
    //   resourceList + " call data " + JSON.stringify(this.slotReservation)
    // );
  }

  checkSlotReservationList() {
    for (let i = 0; i < this.slotReservation.businessServiceTypes.length; i++) {
      if (this.slotReservation.businessServiceTypes[i].slots.length === 0) {
        this.slotReservation.businessServiceTypes.splice(
          this.slotReservation.businessServiceTypes.indexOf(
            this.slotReservation.businessServiceTypes[i]
          ),
          1
        );
      } else {
        for (
          let j = 0;
          j < this.slotReservation.businessServiceTypes[i].slots.length;
          j++
        ) {
          if (
            this.slotReservation.businessServiceTypes[i].slots[j].resourceList
              .length === 0
          ) {
            this.slotReservation.businessServiceTypes[i].slots.splice(
              this.slotReservation.businessServiceTypes[i].slots.indexOf(
                this.slotReservation.businessServiceTypes[i].slots[j]
              ),
              1
            );
          } else {
            for (
              let k = 0;
              k <
              this.slotReservation.businessServiceTypes[i].slots[j].resourceList
                .length;
              k++
            ) {
              if (
                this.slotReservation.businessServiceTypes[i].slots[j]
                  .resourceList[k].bookedTimings.length === 0
              ) {
                this.slotReservation.businessServiceTypes[i].slots[
                  j
                ].resourceList.splice(
                  this.slotReservation.businessServiceTypes[i].slots[
                    j
                  ].resourceList.indexOf(
                    this.slotReservation.businessServiceTypes[i].slots[j]
                      .resourceList[k]
                  ),
                  1
                );
              }
            }
          }
        }
      }
    }
  }

  priceCalculate() {
    if (
      this.businessUser.gstNumber == null ||
      this.businessUser.gstNumber == undefined ||
      this.businessUser.gstNumber == ""
    ) {
      this.slotReservation.totalAmount = 0.0;
      this.slotReservation.beforeTaxAmount = 0.0;
      this.slotCount = 0;
      for (const serviceTypes of this.slotReservation.businessServiceTypes) {
        for (const slots of serviceTypes.slots) {
          let tempSlotCount = 0;
          for (const resource of slots.resourceList) {
            for (const time of resource.bookedTimings) {
              tempSlotCount += 1;
            }
          }
          // this.slotReservation.totalAmount += slots.price * tempSlotCount;
          this.slotReservation.beforeTaxAmount +=
            slots.beforeTax * tempSlotCount;
          this.slotCount += tempSlotCount;
          this.slotReservation.totalAmount =
            this.slotReservation.beforeTaxAmount;
        }
      }
      this.slotReservation.taxAmount =
        this.slotReservation.totalAmount - this.slotReservation.beforeTaxAmount;
      this.slotReservation.afterTaxAmount = this.slotReservation.totalAmount;
    } else {
      this.slotReservation.totalAmount = 0.0;
      this.slotReservation.beforeTaxAmount = 0.0;
      this.slotCount = 0;
      for (const serviceTypes of this.slotReservation.businessServiceTypes) {
        for (const slots of serviceTypes.slots) {
          let tempSlotCount = 0;
          for (const resource of slots.resourceList) {
            for (const time of resource.bookedTimings) {
              tempSlotCount += 1;
            }
          }
          this.slotReservation.totalAmount += slots.beforeTax * tempSlotCount;
          this.slotReservation.beforeTaxAmount +=
            slots.beforeTax * tempSlotCount;
          this.slotCount += tempSlotCount;
        }
      }
      this.slotReservation.taxAmount =
        this.slotReservation.totalAmount - this.slotReservation.beforeTaxAmount;
      this.slotReservation.afterTaxAmount = this.slotReservation.totalAmount;
    }
  }

  onBookingClick() {
    this.token.saveSlotBookData(this.slotReservation);

    // this.token.saveSlotData(this.slotReservation.businessServiceTypes);
    this.router.navigate(["/listing/booking"]);
  }
  onOrderClick() {
    this.orderDto.propertyId = this.businessUser.id;

    
      this.token.saveProductCart(this.addToCartList);
      this.token.saveOrderCart(this.orderDto);
      this.token.saveServiceId(JSON.stringify(this.businessServiceId))

    
    // this.router.navigate(["/listing/order"], navigationExtras);

    this.display = !this.display;
   this.hidebutton();
  }
  hidebutton(){
this.orderNow=false
  }
  goToCart() {
    window.scroll(0, 250);
  }
  goToCart2() {
    window.scroll(0, 750);
  }
  goToCart3() {
    window.scroll(0, 950);
  }
  getPropertyDetails(id: number) {
    this.loader = true;
    this.listingService.findByPropertyId(id).subscribe(
      (data) => {
        this.businessUser = data.body;
        this.token.saveProperty(this.businessUser);
        this.businessTypeName = this.businessUser.businessType;
        // this.getReview(this.businessUser.id);
        this.getBranch(this.businessUser.id);
        this.getCustomerReview(this.businessUser.id);

        if (this.businessUser.businessType == "Accommodation") {
          this.selectOrder = false;
          this.selectHotelBooking = true;
          this.getRoom();
        }

        if (this.businessUser.taxDetails.length > 0) {
          this.taxPercentage = this.businessUser.taxDetails[0].percentage;
        }

        //  Logger.log("Business Data by id", JSON.stringify(this.businessUser));
        this.businessServiceDto = this.businessUser.businessServiceDtoList.find(
          (data) => data.name === this.businessUser.businessType
          
        )
        
        if (
          this.businessServiceDto != undefined &&
          this.businessServiceDto.maxLeadTime != undefined
        ) {
          const maxLead = new Date(
            0,
            0,
            0,
            0,
            this.businessServiceDto.maxLeadTime,
            0
          );

          this.leadMaxDay = Math.floor(
            this.businessServiceDto.maxLeadTime / 1440
          );
          this.leadMaxMin = maxLead.getMinutes();
          this.leadMaxHour = maxLead.getHours();
        }

        if (
          this.businessServiceDto != undefined &&
          this.businessServiceDto.minLeadTime != undefined
        ) {
          const minLead = new Date(
            0,
            0,
            0,
            0,
            this.businessServiceDto.minLeadTime,
            0
          );

          this.leadHour = minLead.getHours();
          this.leadDay = Math.floor(this.businessServiceDto.minLeadTime / 1440);
          this.leadMin = minLead.getMinutes();
        }

        if (
          this.businessServiceDto != undefined &&
          this.businessServiceDto.stdPrepTime != undefined
        ) {
          const prep = new Date(
            0,
            0,
            0,
            0,
            this.businessServiceDto.stdPrepTime,
            0
          );

          this.prepareDay = Math.floor(
            this.businessServiceDto.maxLeadTime / 1440
          );
          this.prepareHour = prep.getHours();
          this.prepareMinute = prep.getMinutes();
        }

        this.claculateDeliveryTime(this.businessServiceDto);

        // for (
        //   let i = 0;
        //   i < this.businessUser.businessServiceDtoList.length;
        //   i++
        // ) {
        //   // Logger.log( ' service id' + this.businessUser.businessServiceDtoList[i].id );
        //   this.getAllGroupProduct(
        //     this.businessUser.businessServiceDtoList[i].id
        //   );
        // }

        this.lat = parseFloat(this.businessUser.latitude);
        this.lng = parseFloat(this.businessUser.longitude);

        this.loader = false;
        this.changeDetectorRefs.detectChanges();
      },
      (error) => {
        this.loader = false;
      }
    );
  }

  slotRevdata(value) {
    if (
      value.customerLocationName != undefined &&
      value.customerLocationName != null
    ) {
      this.slotReservation.customerLocationName = value.customerLocationName;
    }

    if (
      value.businessLocationName != undefined &&
      value.businessLocationName != null
    ) {
      this.slotReservation.businessLocationName = value.businessLocationName;
    }

    if (
      value.businessTermLocation != undefined &&
      value.businessTermLocation != null
    ) {
      this.slotReservation.businessTermLocation = value.businessTermLocation;
    } else {
      this.slotReservation.businessTermLocation = "Location";
    }

    if (
      value.businessTermResource != undefined &&
      value.businessTermResource != null
    ) {
      this.slotReservation.businessTermResource = value.businessTermResource;
      this.resourceLabel = this.slotReservation.businessTermResource;
    } else {
      this.slotReservation.businessTermResource = "Resource";
    }

    if (
      value.canChangeBusinessAddress != undefined &&
      value.canChangeBusinessAddress === true
    ) {
      this.slotReservation.canChangeBusinessAddress = true;
    } else {
      this.slotReservation.canChangeBusinessAddress = false;
    }

    if (
      value.provideBusinessAndCustomerAddress != undefined &&
      value.provideBusinessAndCustomerAddress === true
    ) {
      this.slotReservation.provideBusinessAndCustomerAddress = true;
    } else {
      this.slotReservation.provideBusinessAndCustomerAddress = false;
    }

    // Logger.log("this.resourceLabel  datata rttratac : " + this.resourceLabel);

    this.changeDetectorRefs.detectChanges();
  }

  getPropertyDetailsBySeoName(seoName: string) {
    this.loader = true;
    this.listingService.findPropertyBySEOName(seoName).subscribe(
      (data) => {
        this.businessUser = data.body;
        this.token.saveProperty(this.businessUser);
        // if (this.urlLocation !== undefined && this.urlLocation !== null) {

        // }
        if (seoName === "Book-Star-Test") {
          this.showHideProperty = true;
        } else {
          this.showHideProperty =false;
        }

        if (this.businessUser.plan === "Business Starter") {
          this.isBookingOffering = false;
        } else {
          this.isBookingOffering = true;
        }
        this.selectedRoomMaximumOccupancy = data.body.maximumOccupancy;
        this.selectedRoomAvailableNumber = data.body.numberOfRooms;
        this.getOfferList(seoName);
        this.businessTypeName = this.businessUser.businessType;
        // this.getReview(this.businessUser.id);
        this.getBranch(this.businessUser.id);
        this.getCustomerReview(this.businessUser.id);
        

        this.businessServiceDto = this.businessUser.businessServiceDtoList.find(
          (data) => data.name === this.businessUser.businessType
        );

        // Logger.log(
        //   "Business Data by name businessServiceDto",
        //   JSON.stringify(this.businessServiceDto)
        // );

        if (
          this.businessServiceDto != null &&
          this.businessServiceDto != undefined
        ) {
          this.slotRevdata(this.businessServiceDto);
        }

        if (this.businessUser.businessType == "Accommodation") {
          this.selectOrder = false;
          this.selectHotelBooking = true;
          this.getRoom();
        }
        // this.businessServiceId = this.businessUser.businessServiceDtoList[1].id;
        // this.businessUser.businessServiceDtoList.forEach((business)=>{
        //   if(this.businessUser.businessType =="Restaurants"){
        //     this.businessServiceId = business.id;
        //     this.getAllGroupProduct(this.businessServiceId);
        //   }
        // })
       
        if (this.businessUser.taxDetails.length > 0) {
          this.taxPercentage = this.businessUser.taxDetails[0].percentage;
        }
        // Logger.log('this.businessServiceDto : '+JSON.stringify(this.businessServiceDto));

        if (
          this.businessServiceDto != undefined &&
          this.businessServiceDto.maxLeadTime != undefined
        ) {
          const maxLead = new Date(
            0,
            0,
            0,
            0,
            this.businessServiceDto.maxLeadTime,
            0
          );

          this.leadMaxDay = Math.floor(
            this.businessServiceDto.maxLeadTime / 1440
          );
          this.leadMaxMin = maxLead.getMinutes();
          this.leadMaxHour = maxLead.getHours();
        }

        if (
          this.businessServiceDto != undefined &&
          this.businessServiceDto.minLeadTime != undefined
        ) {
          const minLead = new Date(
            0,
            0,
            0,
            0,
            this.businessServiceDto.minLeadTime,
            0
          );

          this.leadHour = minLead.getHours();
          this.leadDay = Math.floor(this.businessServiceDto.minLeadTime / 1440);
          this.leadMin = minLead.getMinutes();
        }

        if (
          this.businessServiceDto != undefined &&
          this.businessServiceDto.stdPrepTime != undefined
        ) {
          const prep = new Date(
            0,
            0,
            0,
            0,
            this.businessServiceDto.stdPrepTime,
            0
          );

          this.prepareDay = Math.floor(
            this.businessServiceDto.maxLeadTime / 1440
          );
          this.prepareHour = prep.getHours();
          this.prepareMinute = prep.getMinutes();
        }

        if (
          this.businessServiceDto != null &&
          this.businessServiceDto != undefined
        ) {
          this.claculateDeliveryTime(this.businessServiceDto);
        }

        // for (
        //   let i = 0;
        //   i < this.businessUser.businessServiceDtoList.length;
        //   i++
        // ) {
        //   // Logger.log( ' service id' + this.businessUser.businessServiceDtoList[i].id );
        //   this.getAllGroupProduct( this.businessUser.businessServiceDtoList[i].id);
        // }
        this.businessServiceId = this.businessUser.businessServiceDtoList[1]?.id;
        this.businessUser.businessServiceDtoList.forEach((business)=>{
          if(business.name=="Restaurants"){
            this.businessServiceId = business.id;
            this.getAllGroupProduct(this.businessServiceId);
          }
        })
        

        this.lat = parseFloat(this.businessUser.latitude);
        this.lng = parseFloat(this.businessUser.longitude);

        this.loader = false;
        this.changeDetectorRefs.detectChanges();
        this.TriggerEventService.newEvent(this.urlLocation);
        if (this.urlLocation === "true") {
          this.isBookOneLocal = false;
          this.selectOrder = true;
          this.selectBooking = false;
          this.selectHotelBooking = false;
        }
      },
      (error) => {
        this.loader = false;
      }
    );
  }
  setService(ServiceId: any) {
    this.businessServiceId = ServiceId;
    this.businessService.id = ServiceId;

    this.businessServices = [];
    this.businessServices.push(this.businessService);

    this.productGroupsList = [];
    //this.businessServices =[];
    this.getAllGroupProduct(ServiceId);
  }
  onRoomBooking(room) {
    this.dateModel.room = room;

    // const navigationExtras: NavigationExtras = {
    //   queryParams: {
    //       dateob: JSON.stringify(this.dateModel),
    //   }
    // };
    // this.router.navigate(['/booking/booking'], navigationExtras );
  }

  claculateDeliveryTime(businessServiceDto: BusinessServiceDtoList) {
    this.serviceOpenDto = new ServiceOpenDto();
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    const catagoryRestaurent = ["cafes", "restaurants", "takeaway food"];

    let totalHour = 0;
    let totalDay = 0;
    let totalMin = 0;
    const workingHour = 0;

    totalHour = this.prepareHour + this.leadMaxHour;
    totalDay = this.leadMaxDay + this.prepareDay;
    totalMin = this.leadMaxMin + this.prepareMinute;

    const afterDate: Date = new Date();
    const currentDate: Date = new Date();

    Logger.log("this.businessTypeName : " + this.businessTypeName);

    if (
      catagoryRestaurent.indexOf(this.businessTypeName.toLowerCase().trim()) !=
      -1
    ) {
      afterDate.setDate(afterDate.getDate() + totalDay);
      afterDate.setHours(currentDate.getHours() + totalHour);
      afterDate.setMinutes(totalMin + currentDate.getMinutes());

      if (
        this.businessServiceDto != undefined &&
        this.businessServiceDto.serviceOpenList != undefined &&
        this.businessServiceDto.serviceOpenList.length > 0
      ) {
        this.serviceOpenDto = businessServiceDto.serviceOpenList.find(
          (data) =>
            data.day.toLocaleUpperCase() ===
            days[afterDate.getDay()].toLocaleUpperCase()
        );
      }

      if (this.serviceOpenDto === undefined) {
        Logger.log("off day");
        this.isWorkingTime = false;
      } else {
        const startTime: Date = new Date();
        startTime.setDate(startTime.getDate());
        startTime.setHours(
          Number(this.serviceOpenDto.openingTime.split(":")[0])
        );
        startTime.setMinutes(
          Number(this.serviceOpenDto.openingTime.split(":")[1])
        );

        const endTime: Date = new Date();
        endTime.setDate(endTime.getDate());
        endTime.setHours(Number(this.serviceOpenDto.closingTime.split(":")[0]));
        endTime.setMinutes(
          Number(this.serviceOpenDto.closingTime.split(":")[1])
        );

        if (
          startTime.getTime() <= afterDate.getTime() &&
          afterDate.getTime() <= endTime.getTime()
        ) {
          Logger.log("on time");
          this.isWorkingTime = true;
        } else {
          Logger.log("off time");
          this.isWorkingTime = false;
        }
      }

      this.delivertDateAndTime =
        this.getDateDBFormat(afterDate) +
        " " +
        this.getOrderTimeformatAMPM(afterDate);

      // Logger.log(' this.delivertDateAndTime' + this.delivertDateAndTime);
    } else {
      Logger.log("product");
      this.isWorkingTime = true;
    }
  }

  checkDeliveryTimeHour() {
    let todaysDate = new Date();

    Logger.log(" this.delivertDateAndTime" + this.delivertDateAndTime);
  }

  getOrderTimeformatAMPM(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    const strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  getDateDBFormat(date: Date) {
    let currentDay: string;
    let currentMonth: string;

    if (date.getDate().toString().length == 1) {
      currentDay = "0" + date.getDate();
    } else {
      currentDay = "" + date.getDate();
    }

    if ((date.getMonth() + 1).toString().length == 1) {
      currentMonth = "0" + (date.getMonth() + 1);
    } else {
      currentMonth = "" + (date.getMonth() + 1);
    }

    return currentDay + "-" + currentMonth + "-" + date.getFullYear();
  }

  getAllGroupProduct(businessServiceId: number) {
    this.loader = true;
    this.listingService
      .findProductsByBusinessServiceId(businessServiceId)
      .subscribe(
        (data) => {
          if (
            data.body != null &&
            data.body != undefined &&
            data.body.length > 0
          ) {
            this.productGroupsList.push(data.body);
          }
          this.productGroupList?.forEach(element => {
            
          });

          Logger.log("Product data", JSON.stringify(this.productGroupsList));
          this.loader = false;
          this.changeDetectorRefs.detectChanges();
        },
        (error) => {
          this.loader = false;
        }
      );
  }
  modelChangedProductMinus(product, item, i) {
   
    if (this.addToCartList.some((p) => p.id === product.id) === true) {
      this.addToCart = new AddToCart();
    
      this.addToCart = this.addToCartList.find(
        (cart) => cart.id === product.id
      );
    
      
      // this.quantity = 1;
      if (
        this.addToCart.quantityProduct != null ||
        this.addToCart.quantityProduct != undefined
      ) {
        this.addToCart.quantityProduct = this.addToCart.quantityProduct - 1;
        this.quantityProductItem =this.addToCart.quantityProduct
      } else {
        this.addToCart.quantityProduct = 0;
        this.quantity = 0;
      }

      if (
        this.addToCart.discountedPrice == null ||
        this.addToCart.discountedPrice == 0
      ) {
        this.totalPrice = this.addToCart.sellUnitPrice * this.addToCart.quantityProduct;
      } else {
        this.totalPrice = this.addToCart.discountedPrice * this.addToCart.quantityProduct;
      }
// if (this.addToCart.toppingProductGroupId !=null || this.addToCart.extraProductGroupId !=null) {
//   this.extras = this.addToCart.extraProductGroupDto;

// } else {
  
// }this.addToCart.productDtoList = product.extraProductGroupDto.productDtoList
if (product.extraProductGroupDto?.productDtoList != null || product.extraProductGroupDto?.productDtoList != undefined) {
  this.addToCart.productDtoList = product.extraProductGroupDto?.productDtoList
} 
if (product.addOnProductGroup?.productDtoList != null || product.addOnProductGroup?.productDtoList != undefined) {
  this.addToCart.toppingproductDtoList = product.addOnProductGroup?.productDtoList
} 
      // this.addToCart.quantityProduct = this.quantity;
      this.addToCart.totalPrice = this.totalPrice;

      this.addToCartList[this.addToCartList.indexOf(product)] = this.addToCart;
      this.changeDetectorRefs.detectChanges();
    } 
    if (this.addToCart.quantityProduct === 0) {
     this.removeProduct(product);
     
     product.unitsInOrder =0;
    }

  }

  addProductToCart(product, item, i) {
    product.unitsInOrder =1;
    if (this.addToCartList.some((p) => p.id === product.id) === true) {
      this.addToCart = new AddToCart();

      this.addToCart = this.addToCartList.find(
        (cart) => cart.id === product.id
      );
      // product.unitsInOrder =1;
      this.quantity = 1;
 
      
      if (
        this.addToCart.quantityProduct != null ||
        this.addToCart.quantityProduct != undefined
      ) {
        this.quantity = this.addToCart.quantityProduct;
        this.quantity = this.quantity + 1;
      } else {
        this.addToCart.quantityProduct = 1;
        this.quantity = 1;
      }

      if (
        this.addToCart.discountedPrice == null ||
        this.addToCart.discountedPrice == 0
      ) {
        this.totalPrice = this.addToCart.sellUnitPrice * this.quantity;
      } else {
        this.totalPrice = this.addToCart.discountedPrice * this.quantity;
      }
// if (this.addToCart.toppingProductGroupId !=null || this.addToCart.extraProductGroupId !=null) {
//   this.extras = this.addToCart.extraProductGroupDto;
//   console.log("the extras are :" + this.extras)
// } else {
  
// }this.addToCart.productDtoList = product.extraProductGroupDto.productDtoList
if (product.extraProductGroupDto?.productDtoList != null || product.extraProductGroupDto?.productDtoList != undefined) {
  this.addToCart.productDtoList = product.extraProductGroupDto?.productDtoList
} 
if (product.addOnProductGroup?.productDtoList != null || product.addOnProductGroup?.productDtoList != undefined) {
  this.addToCart.toppingproductDtoList = product.addOnProductGroup?.productDtoList
} 
      this.addToCart.quantityProduct = this.quantity;
      this.addToCart.totalPrice = this.totalPrice;

      this.addToCartList[this.addToCartList.indexOf(product)] = this.addToCart;
      this.changeDetectorRefs.detectChanges();
    } else {
      this.addToCart = new AddToCart();
      if (this.addToCart.quantityProduct != null) {
        // this.addToCart = product;
        this.addToCart.quantityProduct = product.quantityProduct;
      }else{
        this.addToCart.quantityProduct = 1;
      }
      
      this.addToCart.id = product.id;
      this.addToCart.productCode = product.productCode;
      this.addToCart.name = product.name;
      this.addToCart.imageList = product.imageList;
      this.addToCart.shortDescription = product.shortDescription;
      this.addToCart.description = product.description;

      this.addToCart.productGroupId = product.productGroupId;
      this.addToCart.supplierId = product.supplierId;
this.addToCart.extraProductGroupId = product.extraProductGroupId;
      this.addToCart.sellUnitPrice = product.sellUnitPrice;
      this.addToCart.buyUnitPrice = product.buyUnitPrice;
      this.addToCart.unitsInStock = product.unitsInStock;
      this.addToCart.unitsInOrder = this.addToCart.quantityProduct;
      this.addToCart.toppingProductGroupId = product.toppingProductGroupId;
      this.addToCart.discountedPrice = product.discountedPrice;
      if (product.extraProductGroupDto?.productDtoList != null || product.extraProductGroupDto?.productDtoList != undefined) {
        this.addToCart.productDtoList = product.extraProductGroupDto?.productDtoList
      } 
      if (product.addOnProductGroup?.productDtoList != null || product.addOnProductGroup?.productDtoList != undefined) {
        this.addToCart.toppingproductDtoList = product.addOnProductGroup?.productDtoList
      } 
      // this.addToCart.productDtoList = product.productDtoList;

      if (
        this.addToCart.discountedPrice == null ||
        this.addToCart.discountedPrice == 0
      ) {
        this.addToCart.totalPrice = product.sellUnitPrice;
      } else {
        this.addToCart.totalPrice = product.discountedPrice;
      }

      this.addToCartList.push(this.addToCart);
      this.changeDetectorRefs.detectChanges();
    }
  //  this.showSuccess(this.showSuccess(this.contentDialog);) 
  this.showSuccess(this.contentDialog);
 
  }

  calculateQuantity() {
    this.totalQuantity = 0;

     for (let i = 0 ; i < this.addToCartList.length ; i++) {

      if (this.addToCartList[i].variationDtoList != undefined) {
        for (let j = 0 ; j < this.addToCartList[i].variationDtoList.length ; j++) {
          this.totalQuantity = this.totalQuantity + 1;
        }
      } else {
        this.totalQuantity = this.totalQuantity + 1;
      }

     }

     return this.totalQuantity;
  }

  openNav() {
    document.getElementById("mySidenav").style.width = "120px";
    document.getElementById("main-wrapper").style.marginLeft = "120px";

    
  }
  closeNav() {
    document.getElementById("mySidenav").style.width = "0";
    document.getElementById("main-wrapper").style.marginLeft = "0";

  }
  addProductWithVariationToCart(product, variation, i) {
    if (this.addToCartList.some((p) => p.id === product.id) === true) {
      // Logger.log('Variation Exist check');
      this.addToCart = new AddToCart();

      this.addToCart = this.addToCartList.find(
        (cart) => cart.id === product.id
      );

      this.proVariationDtoLists = [];
      this.proVariationDtoList = new productVariationDtoList();

      this.proVariationDtoLists = this.addToCart.variationDtoList;

      if (
        this.proVariationDtoLists.some((c) => c.code === variation.code) ===
        true
      ) {
        this.proVariationDtoList = this.proVariationDtoLists.find(
          (list) => list.code === variation.code
        );

        this.quantityVariation = this.proVariationDtoList.quantityVariation;
        this.quantityVariation = this.quantityVariation + 1;

        if (
          this.proVariationDtoList.discountedPrice == null ||
          this.proVariationDtoList.discountedPrice == 0
        ) {
          this.totalPriceVariation =
            this.quantityVariation * this.proVariationDtoList.sellUnitPrice;
        } else {
          this.totalPriceVariation =
            this.quantityVariation * this.proVariationDtoList.discountedPrice;
        }
        this.proVariationDtoList.quantityVariation = this.quantityVariation;
        this.proVariationDtoList.totalPrice = this.totalPriceVariation;

        this.proVariationDtoLists[
          this.proVariationDtoLists.indexOf(this.proVariationDtoList)
        ] = this.proVariationDtoList;

        this.addToCart.variationDtoList = this.proVariationDtoLists;

        this.addToCartList[this.addToCartList.indexOf(product)] =
          this.addToCart;
        this.changeDetectorRefs.detectChanges();
      } else {
        this.proVariationDtoList = new productVariationDtoList();

        this.proVariationDtoList = variation;

        this.addToCart.quantityProduct = product.quantityVariation;
        if (
          this.proVariationDtoList.discountedPrice == null ||
          this.proVariationDtoList.discountedPrice == 0
        ) {
          this.totalPriceVariation = this.proVariationDtoList.sellUnitPrice;
        } else {
          this.totalPriceVariation = this.proVariationDtoList.discountedPrice;
        }
        this.proVariationDtoList.quantityVariation = this.quantityVariation;
        this.proVariationDtoList.totalPrice = this.totalPriceVariation;

        this.proVariationDtoLists.push(this.proVariationDtoList);

        this.addToCart.variationDtoList = this.proVariationDtoLists;
        this.addToCartList[this.addToCartList.indexOf(product)] =
          this.addToCart;
        this.changeDetectorRefs.detectChanges();
      }
    } else {
      this.addToCart = new AddToCart();
      // this.addToCart=product;
      this.addToCart.id = product.id;
      this.addToCart.quantityProduct = product.quantityVariation;
      this.addToCart.productCode = product.productCode;
      this.addToCart.name = product.name;
      this.addToCart.imageList = product.imageList;
      this.addToCart.shortDescription = product.shortDescription;
      this.addToCart.description = product.description;

      this.addToCart.productGroupId = product.productGroupId;
      this.addToCart.supplierId = product.supplierId;

      this.addToCart.sellUnitPrice = product.sellUnitPrice;
      this.addToCart.buyUnitPrice = product.buyUnitPrice;
      this.addToCart.unitsInStock = product.unitsInStock;
      this.addToCart.unitsInOrder = product.unitsInOrder;
      this.addToCart.discountedPrice = product.discountedPrice;

      this.proVariationDtoLists = [];
      this.proVariationDtoList = new productVariationDtoList();

      this.proVariationDtoList = variation;

      this.quantityVariation = 1;

      if (
        this.proVariationDtoList.discountedPrice == null ||
        this.proVariationDtoList.discountedPrice == 0
      ) {
        this.totalPriceVariation = this.proVariationDtoList.sellUnitPrice;
      } else {
        this.totalPriceVariation = this.proVariationDtoList.discountedPrice;
      }
      // this.totalPriceVariation = this.proVariationDtoList.sellUnitPrice;

      this.proVariationDtoList.quantityVariation = this.quantityVariation;
      this.proVariationDtoList.totalPrice = this.totalPriceVariation;
      // this.proVariationDtoList.name =

      this.proVariationDtoLists.push(this.proVariationDtoList);

      this.addToCart.variationDtoList = this.proVariationDtoLists;
      this.addToCart.quantityProduct = 0;
      this.addToCart.totalPrice = 0;
      this.addToCartList.push(this.addToCart);
      this.changeDetectorRefs.detectChanges();
    }
    //Logger.log("this.addToCartList:" + JSON.stringify(this.addToCartList));
  }

  removeProduct(product) {
    this.addToCartList = this.addToCartList.filter(
      ({ name }) => name !== product.name
    );
    window.sessionStorage.setItem(PRODUCT_CART, JSON.stringify(this.addToCartList));
    // this.token.saveProductCart(this.addToCartList);
  }
  

  removeProductVariatiion(product) {

    this.proVariationDtoLists = [];
    this.proVariationDtoList = new productVariationDtoList();

    this.proVariationDtoLists = product.variationDtoList;

    for (let i = 0; i < this.proVariationDtoLists.length; i++) {
      if (this.proVariationDtoLists[i].quantityVariation === 0) {
        const index: number = this.proVariationDtoLists.indexOf(
          this.proVariationDtoLists[i]
        );
        if (index !== -1) {
          this.proVariationDtoLists.splice(index, 1);

          // Logger.log(JSON.stringify(this.proVariationDtoLists));

          if (this.proVariationDtoLists.length === 0) {
             
            this.addToCartList = this.addToCartList.filter(
              ({ name }) => name !== product.name
            );
            window.sessionStorage.setItem(PRODUCT_CART, JSON.stringify(this.proVariationDtoLists));
          }
        }
      }
    }
// this.token.saveProductCart(this.addToCartList);
    
  }
  accommodationClick() {
    this.selectOrder = false;
    this.selectBooking = false;
    this.selectHotelBooking = true;
  }
  // removeProduct1(product , index) {
  //   this.productRemoved.emit(product);
  // }
  aboutClick() {
    this.selectOrder = false;
    this.selectBooking = false;
    this.selectHotelBooking = false;
  }
//   public selectPill(index:number) {
//     this.activePillIndex = index;
// }
  menuClick() {
    this.selectOrder = true;
    this.selectBooking = false;
    this.selectHotelBooking = false;
  }
  serviceClick() {
    this.selectOrder = false;
    this.selectBooking = true;
    this.selectHotelBooking = false;
  }
  businessTimeClick() {
    this.selectOrder = false;
    this.selectBooking = false;
    this.selectHotelBooking = false;
  }
  locationClick() {
    this.selectOrder = false;
    this.selectBooking = false;
    this.selectHotelBooking = false;
  }

  checckOfferingByMethod(serviceTypes, isBooking) {
    this.serviceTypeFilterData = serviceTypes;

    this.serviceTypeFilterData = this.serviceTypeFilterData.filter((item) => {
      const searchResult =
        (item.bookable === null && isBooking === true) ||
        (item.bookable === undefined && isBooking === true) ||
        (item.bookable === isBooking && isBooking === true) ||
        (item.bookable === isBooking && isBooking === false);

      return searchResult;
    });

    if (
      this.serviceTypeFilterData === undefined ||
      this.serviceTypeFilterData === null
    ) {
      return true;
    } else if (this.serviceTypeFilterData.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  selectOfferings(bServiceDtoList) {
    // this.selectService = undefined;
    // this.isReservationList = false;
    this.ngbDate = undefined;
    this.dateSelected = false;
    // this.resourceSelected = false;
    // this.slotTimeIndex = undefined;
    // const date = new Date();
    // const dataEnabled: NgbDateStruct = {
    //   day: date.getDate(),
    //   month: date.getMonth() + 1,
    //   year: date.getFullYear(),
    // };
    // this.enabledDates.push(dataEnabled);
  }

  getResarvationList(searchDate) {
    const date = searchDate;
    const searchConDate = date.split("-").reverse().join("-");
    this.loaderSlot = true;
    this.slotDateLists = [];
    // Logger.log("service type id: ", this.selectService);
    // Logger.log("searchConDate: ", searchConDate);
    this.slotReservation.date = searchConDate;
    this.reservationService
      .getSlotsByBusinessServiceTypeAndDate(
        this.slotReservation,
        this.selectService
      )
      .subscribe(
        (data) => {
          this.slots = data.body;
          Logger.log("this.slots " + JSON.stringify(this.slots));
          this.slotDateList = this.slot.slotDateList;

          this.enabledDates = [];
          this.slotLocationLists = [];
          this.slotResourceLists = [];
          this.slotDateListss = [];

          this.isDisabled = (
            date: NgbDateStruct,
            current: { month: number; year: number }
          ) => {
            return this.enabledDates.find((x) => NgbDate.from(x).equals(date))
              ? false
              : true;
          };
          // Logger.log(
          //   'this.slotDateLists : ' + JSON.stringify(this.enabledDates)
          // );
          // Logger.log(
          //   'slotResourceLists : ' + JSON.stringify(this.slotResourceLists)
          // );
          // Logger.log('slotLists : ' + JSON.stringify(this.slotResourceLists));

          // Logger.log(
          //   'this. this.slotLocationLists : ' +
          //     JSON.stringify(this.slotLocationLists)
          // );

          // this.applySlotFilter('15-05-2020', '', 'Chair 2');
          // Logger.log('applySlotFilter ' + JSON.stringify(this.slotDateFilter));

          // this.isReservationList = true;
          this.loaderSlot = false;
          this.changeDetectorRefs.detectChanges();
        },
        (error) => {
          this.loaderSlot = false;
        }
      );
  }
  getResarvationListByDate() {
    this.loaderSlot = true;
    this.slotDateLists = [];
    // Logger.log('service type id: ', this.selectService);
    // Logger.log('service Date: ', this.slotReservation.date);
    this.reservationService
      .getSlotsByBusinessServiceTypeAndDate(
        this.slotReservation,
        this.selectService
      )
      .subscribe(
        (data) => {
          this.slots = data.body;
          // Logger.log('this.slot ' + JSON.stringify(this.slot));
          this.slotDateList = this.slotByDate.slotDateList;

          this.enabledDates = [];
          this.slotLocationLists = [];
          this.slotResourceLists = [];
          this.slotDateListss = [];

          for (let i = 0; i < this.slotByDate.slotDateList.length; i++) {
            if (
              this.slotDateLists.indexOf(
                this.slotByDate.slotDateList[i].date
              ) == -1
            ) {
              this.slotDateLists.push(this.slotByDate.slotDateList[i].date);

              const date = new Date(this.slotByDate.slotDateList[i].date);

              const dataEnabled: NgbDateStruct = {
                day: date.getDate(),
                month: date.getMonth() + 1,
                year: date.getFullYear(),
              };

              this.enabledDates.push(dataEnabled);
            }

            // ...resource and location
            if (
              this.slotResourceLists.indexOf(
                this.slotByDate.slotDateList[i].resource.resourceName
              ) == -1
            ) {
              this.slotResourceLists.push(
                this.slotByDate.slotDateList[i].resource.resourceName
              );
            }

            if (
              this.slotLocationLists.indexOf(
                this.slotByDate.slotDateList[i].location.locationName
              ) == -1
            ) {
              this.slotLocationLists.push(
                this.slotByDate.slotDateList[i].location.locationName
              );
            }
          }
          // this.isDisabled = (date: NgbDateStruct, current: {month: number, year: number})=> {
          //   return this.enabledDates.find(x => NgbDate.from(x).equals(date))? false : true;
          // }
          // Logger.log(
          //   'this.slotDateLists : ' + JSON.stringify(this.enabledDates)
          // );
          // Logger.log(
          //   'slotResourceLists : ' + JSON.stringify(this.slotResourceLists)
          // );
          // Logger.log(
          //   'this. this.slotLocationLists : ' +
          //     JSON.stringify(this.slotLocationLists)
          // );

          // this.applySlotFilter('15-05-2020', '', 'Chair 2');
          // Logger.log('applySlotFilter ' + JSON.stringify(this.slotDateFilter));

          this.isReservationList = true;
          this.loaderSlot = false;
          this.changeDetectorRefs.detectChanges();
        },
        (error) => {
          this.loaderSlot = false;
        }
      );
  }

  applySlotDateFilter(dateString: string) {
    // this.slotDateFilter = this.slot.slotDateList.filter((item) => {
    //   const searchResult =
    //     item.date != null &&
    //     this.dateService
    //       .convertMillisecondsToDateFormat(item.date)
    //       .toLowerCase()
    //       .indexOf(dateString.toString().trim()) > -1;
    //   return searchResult;
    // });
  }

  applySlotFilter(
    dateString: string,
    ResourceName: string,
    locationName: string
  ) {
    this.slotDateFilter = this.slot.slotDateList.filter((item) => {
      const searchResult =
        item.location.locationName != null &&
        item.location.locationName
          .toLowerCase()
          .trim()
          .indexOf(locationName.toLowerCase().trim()) > -1 &&
        item.resource.resourceName != null &&
        item.resource.resourceName
          .toLowerCase()
          .trim()
          .indexOf(ResourceName.toLowerCase().trim()) > -1 &&
        item.date != null &&
        this.dateService
          .convertMillisecondsToDateFormat(item.date)
          .toLowerCase()
          .indexOf(dateString.toString().trim()) > -1;
      return searchResult;
    });
  }

  applySlotLocationFilter(dateString: string, locationName: string) {
    this.slotDateFilter = this.slot.slotDateList.filter((item) => {
      const searchResult =
        item.location.locationName != null &&
        item.location.locationName
          .toLowerCase()
          .trim()
          .indexOf(locationName.toLowerCase().trim()) > -1 &&
        item.date != null &&
        this.dateService
          .convertMillisecondsToDateFormat(item.date)
          .toLowerCase()
          .indexOf(dateString) > -1;
      return searchResult;
    });
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
      this.setFromDate();
    } else if (
      this.fromDate &&
      !this.toDate &&
      date &&
      date.after(this.fromDate)
    ) {
      this.toDate = date;
      this.setToDate();
    } else {
      this.toDate = null;
      this.fromDate = date;
      this.setFromDate();
    }
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed))
      ? NgbDate.from(parsed)
      : currentValue;
  }

  getRoom() {
    this.hotelBookingService
      .getRoomDetailsByPropertyId(this.businessUser.id)
      .subscribe(
        (response) => {
          // console .log('response room choose room ' + JSON.stringify(response.body));
          this.rooms = response.body;
        },
        (error) => {
          if (error instanceof HttpErrorResponse) {
          }
        }
      );
    this.booking.noOfPersons = 1;
    this.booking.noOfChildren = 1;
    this.booking.noOfRooms = 1;
  }
  getRoomByDate(fromDate: string, toDate: string) {
    this.hotelBookingService
      .getRoomDetailsByPropertyIdAndDate(this.businessUser.id, fromDate, toDate)
      .subscribe(
        (response) => {
          if (response.status == 200) {
            this.roomAvailability = true;
            // Logger.log('getRoomByDate ' + JSON.stringify(response.body));

            this.availableRooms = response.body;
            // Logger.log('getRoomByDate ' + JSON.stringify(this.availableRooms));
          } else {
          }
        },
        (error) => {
          if (error instanceof HttpErrorResponse) {
          }
        }
      );
  }

  onPlanSelected(plan) {
    this.checkAvailabilityStatus = false;
    this.checkAvailabilityStatusHide = true;
    this.checkAvailabilityStatusName = undefined;
    this.planDetails = plan;
    this.booking.planCode = plan.code;
    this.booking.roomRatePlanName = plan.name;
    this.booking.roomPrice = plan.amount;
    this.fromDate = undefined;
    this.toDate = undefined;
    this.booking.fromDate = undefined;
    this.booking.toDate = undefined;
    // Logger.log(JSON.stringify(this.booking));
    // Logger.log(JSON.stringify(this.checkAvailabilityStatusHide));
    this.changeDetectorRefs.detectChanges();
  }

  onRoomBook(roomId, index) {
    this.checkAvailabilityStatus = false;
    this.selectedIndex = index;
    this.getPlan(roomId);
  }

  getPlan(roomId: string) {
    this.loader = true;
    this.hotelBookingService
      .getPlan(String(this.businessUser.id), roomId)
      .subscribe(
        (data) => {
          if (data.status === 200) {
            this.plans = data.body;
            this.loader = false;
            this.changeDetectorRefs.detectChanges();
            this.hasPlan = true;

            // Logger.log("this.plans : " + JSON.stringify(this.plans));
          } else if (data.status === 404) {
            this.hasPlan = false;
            this.plans = null;
            this.changeDetectorRefs.detectChanges();
            Logger.log("this.plans : " + JSON.stringify(this.plans));
          }
        },
        (error) => {
          this.hasPlan = false;
          this.plans = null;
          this.changeDetectorRefs.detectChanges();
          //  Logger.log("this.plans : " + JSON.stringify(this.plans));
          // Logger.log(JSON.stringify(error));
          this.loader = false;
        }
      );
  }

  setFromDate() {
    this.booking.fromDate = this.getDateFormatYearMonthDay(
      this.fromDate.day,
      this.fromDate.month,
      this.fromDate.year
    );
  }
  setToDate() {
    this.booking.toDate = this.getDateFormatYearMonthDay(
      this.toDate.day,
      this.toDate.month,
      this.toDate.year
    );
  }
  checkingAvailability() {
    this.loaderHotelBooking = true;
    this.checkAvailabilityStatusHide = false;
    this.booking.propertyId = this.businessUser.id;

    // Logger.log("checkAvailability submit" + JSON.stringify(this.booking));

    this.hotelBookingService.checkAvailability(this.booking).subscribe(
      (response) => {
        this.loaderHotelBooking = false;
        Logger.log("checkAvailability " + JSON.stringify(response.body));
        this.checkAvailabilityStatus = response.body.available;
        this.booking.bookingAmount = response.body.bookingAmount;
        this.booking.noOfPersons = response.body.noOfPersons;
        this.booking.noOfChildren = response.body.noOfChildren;
        if (response.body.noOfExtraChild !== null) {
          this.booking.noOfExtraChild = response.body.noOfExtraChild;
        } else {
          this.booking.noOfExtraChild = 0;
        }
        if (response.body.noOfExtraPerson !== null) {
          this.booking.noOfExtraPerson = response.body.noOfExtraPerson;
        } else {
          this.booking.noOfExtraPerson = 0;
        }

        this.booking.extraChildCharge = response.body.extraChildCharge;
        this.booking.extraPersonCharge = response.body.extraPersonCharge;

        if (response.body.available == true) {
          this.checkAvailabilityStatusName = "Available";
        } else {
          this.checkAvailabilityStatusName = "Not Available";
        }

        Logger.log("checkAvailability " + JSON.stringify(this.booking));
      },
      (error) => {
        if (error instanceof HttpErrorResponse) {
          Logger.log("checkAvailability error");
        }
      }
    );
  }
  bookRoomNow() {
    this.changeDetectorRefs.detectChanges();

    this.token.saveBookingData(this.booking);
    this.router.navigate(["/listing/hotel-booking"]);
  }
  showMessage() {
    this.isSuccess = false;
    this.headerTitle = "Warning!";
    this.bodyMessage = "This Slot Not Available";
    this.showWarning(this.bodyMessage);
  }
  showSuccess(content) {
    this.alertType = "success";
    this.showAlert = true;
  }
  showWarning(content) {
    this.alertType = "warning";
    this.showAlert = true;
    setTimeout(() => {
      this.showAlert = false;
      this.changeDetectorRefs.detectChanges();
    }, 3000);
  }
  showDanger(content) {
    this.alertType = "danger";
    this.showAlert = true;
    setTimeout(() => {
      this.showAlert = false;
      this.changeDetectorRefs.detectChanges();
    }, 3000);
  }
}
