
export class ServiceOpenDto {

    breakFromTime: string;
    breakToTime: string;
    closingTime: string;
    day: string;
    openingTime: string;

    constructor() {}
}