import { Address } from './address';
import { Customer } from './customer';
import { BusinessServiceTypes } from './businessServiceTypes';

export class SlotReservation {
  id: number;
  slotId: number;
  date: string;
  bookingStatus: string;
  companyName: string;
  afterTaxAmount: number;
  beforeTaxAmount: number;
  businessName: string;
  businessReservationNumber: string;
  paymentId: number;
  businessTypeId: number;
  businessTypeName: string;
  slotTimingId: number;
  serviceAddress: Address;
  duration: number;
  email: string;
  firstName: string;
  lastName: string;
  fromTime: string;
  toTime: string;
  locationName: string;
  mobile: string;
  modeOfPayment: string;
  propertyId: number;
  noOfPerson: number;
  notes: string;
  resourceName: string;
  totalAmount: number;
  taxAmount: number;
  currency: string;
  businessServiceTypes: BusinessServiceTypes[];
  customerDtoList: Customer[];

  
  businessLocationName: string;
  customerLocationName: string;
  canChangeBusinessAddress: boolean;
  provideBusinessAndCustomerAddress: boolean;

  businessTermLocation: string;
  businessTermResource: string;
  slotReservationDtos: SlotReservation[];
  isBookable : boolean; 
  startTime: string;
  finishTime: string;
  constructor() {}
}
