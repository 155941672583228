import { OrderDto } from './model/orderDto';
import { SlotReservation } from 'src/app/model/slotReservation';
import { Product } from 'src/app/model/product';
import { BusinessUser } from 'src/app/model/user';
import { Injectable } from '@angular/core';
import { SlotBookData } from './model/SlotBookData';
import { Booking } from './model/booking';
import { BusinessServiceTypes } from './model/businessServiceTypes';
import { Payment } from './model/payment';

const BOOKSLOTDATA = 'slotbookdata';
const SLOTDATA = 'slotdata';
const BOOKINGDATA = 'booking';
const BUSINESS_SERVICE = 'businessservice';
const PRODUCT_ADD_TO_CART = 'PRODUCT_ADD_TO_CART';
const PRODUCT_CART = 'productCart';
const ORDER_CART ='orderCart';
const SERVICE_ID ='businessServiceId'
const businessTypeGroupListDATA = 'businessTypeGroupList';
const businessTypeListDATA = 'businessTypeList';
const BUSINESS_PROPERTY = 'businessProperty';
const CURRENT_CITY = 'currentCity';
const ORDERDATA = 'ORDER_DATA';
const PAYMENT = "payment";
@Injectable()
export class TokenStorage {
  constructor() {}
  clearAll() {
    window.sessionStorage.clear();
  }
  public getOrderData(): any {
    return JSON.parse(sessionStorage.getItem(ORDERDATA));
  }
  clearBusiness() {
    window.sessionStorage.removeItem(BUSINESS_SERVICE);
    window.sessionStorage.removeItem(BOOKSLOTDATA);
    window.sessionStorage.removeItem(BOOKINGDATA);
    window.sessionStorage.removeItem(BUSINESS_PROPERTY);

  }
  public saveOrderData(OrderData: any) {
    window.sessionStorage.removeItem(ORDERDATA);
    if (OrderData != null) {
      window.sessionStorage.setItem(ORDERDATA, JSON.stringify(OrderData));
    } else {
      window.sessionStorage.setItem(ORDERDATA, null);
    }
  }
  public clearOrderData() {
    return window.sessionStorage.removeItem(ORDERDATA);
  }

  public savePropertyData(property) {
    window.sessionStorage.removeItem(BUSINESS_PROPERTY);
    if (property !== null || property !== undefined) {
      window.sessionStorage.setItem(BUSINESS_PROPERTY, JSON.stringify(property));
    } else {
      window.sessionStorage.setItem(BUSINESS_PROPERTY, null);
    }
  }
  public getPropertyData(): BusinessUser {
    return JSON.parse(localStorage.getItem(BUSINESS_PROPERTY) as string);
  }
  public clearADDToCart() {
    return window.sessionStorage.removeItem(PRODUCT_ADD_TO_CART);
  }
   // Payment
   public savePaymentData(payment) {
    window.sessionStorage.removeItem(PAYMENT);
    if (payment !== null || payment !== undefined) {
      window.sessionStorage.setItem( PAYMENT, JSON.stringify(payment) );
    } else {
      window.sessionStorage.setItem(PAYMENT, null);
    }
  }
// BusinessTypeGroupList
  public saveBusinessTypeGroupListData(businessTypeGroupList) {
    window.sessionStorage.removeItem(businessTypeGroupListDATA);
    if (businessTypeGroupList !== null || businessTypeGroupList !== undefined) {
      window.sessionStorage.setItem(
        businessTypeGroupListDATA,
        JSON.stringify(businessTypeGroupList)
      );
    } else {
      window.sessionStorage.setItem(businessTypeGroupListDATA, null);
    }
  }

  public getBusinessTypeListGroupData(): any[] {
    return JSON.parse(sessionStorage.getItem(businessTypeGroupListDATA));
  }
// BusinessTypeList
  public saveBusinessTypeListData(businessTypeList) {
    window.sessionStorage.removeItem(businessTypeListDATA);
    if (businessTypeList !== null || businessTypeList !== undefined) {
      window.sessionStorage.setItem(
        businessTypeListDATA,
        JSON.stringify(businessTypeList)
      );
    } else {
      window.sessionStorage.setItem(businessTypeListDATA, null);
    }
  }

  public getBusinessTypeListData(): any[] {
    return JSON.parse(sessionStorage.getItem(businessTypeListDATA));
  }
// Property
public saveProperty(businessUser: BusinessUser){

  window.sessionStorage.removeItem(BUSINESS_PROPERTY);
  if (businessUser !== null || businessUser !== undefined) {
    window.sessionStorage.setItem(
      BUSINESS_PROPERTY,
      JSON.stringify(businessUser)
    );
  } else {
    window.sessionStorage.setItem(BUSINESS_PROPERTY, null);
  }
}

public getProperty(): BusinessUser {
  return JSON.parse(sessionStorage.getItem(BUSINESS_PROPERTY));
}
public getPaymentData(): Payment {
  return JSON.parse(sessionStorage.getItem(PAYMENT));
}
public clearPaymentData() {
  return window.sessionStorage.removeItem(PAYMENT);
}




// SlotBook
  public saveSlotBookData(slotData: SlotReservation) {
    window.sessionStorage.removeItem(BOOKSLOTDATA);
    if (slotData !== null || slotData !== undefined) {
      window.sessionStorage.setItem(BOOKSLOTDATA, JSON.stringify(slotData));
    } else {
      window.sessionStorage.setItem(BOOKSLOTDATA, null);
    }
  }
  
  public getSlotBookData(): SlotReservation {
    return JSON.parse(sessionStorage.getItem(BOOKSLOTDATA));
  }

  public saveSlotData(BusinessServiceTypes: BusinessServiceTypes[]) {
    window.sessionStorage.removeItem(SLOTDATA);
    if (BusinessServiceTypes !== null || BusinessServiceTypes !== undefined) {
      window.sessionStorage.setItem(SLOTDATA, JSON.stringify(BusinessServiceTypes));
    } else {
      window.sessionStorage.setItem(SLOTDATA, null);
    }
  }

  public getSlotData(): BusinessServiceTypes[] {
    return JSON.parse(sessionStorage.getItem(SLOTDATA));
  }

  // Booking
  public saveBookingData(booking: Booking) {
    window.sessionStorage.removeItem(BOOKINGDATA);
    if (booking !== null || booking !== undefined) {
      window.sessionStorage.setItem(BOOKINGDATA, JSON.stringify(booking));
    } else {
      window.sessionStorage.setItem(BOOKINGDATA, null);
    }
  }

  public getBookingData(): Booking {
    return JSON.parse(sessionStorage.getItem(BOOKINGDATA));
  }

  clearHotelBooking(){
    window.sessionStorage.removeItem(BOOKINGDATA);
  }

  //BusinessService
  public saveBusinessService(businessUser: BusinessUser[]) {
    window.sessionStorage.removeItem(BUSINESS_SERVICE);
    if (businessUser !== null || businessUser !== undefined) {
      window.sessionStorage.setItem(
        BUSINESS_SERVICE,
        JSON.stringify(businessUser)
      );
    } else {
      window.sessionStorage.setItem(BUSINESS_SERVICE, null);
    }
  }

  public getBusinessService(): BusinessUser[] {
    return JSON.parse(sessionStorage.getItem(BUSINESS_SERVICE));
  }

// ProductCart
  clearProductCart() {
    window.sessionStorage.removeItem(PRODUCT_CART);
  }

  public saveProductCart(product: any[]) {
    window.sessionStorage.removeItem(PRODUCT_CART);
    if (product !== null || product !== undefined) {
      window.sessionStorage.setItem(PRODUCT_CART, JSON.stringify(product));
    } else {
      window.sessionStorage.setItem(PRODUCT_CART, null);
    }
  }
  public saveOrderCart(OrderDto: any) {
    window.sessionStorage.removeItem(ORDER_CART);
    if (OrderDto !== null || OrderDto !== undefined) {
      window.sessionStorage.setItem(ORDER_CART, JSON.stringify(OrderDto));
    } else {
      window.sessionStorage.setItem(ORDER_CART, null);
    }
  }
  public getOrderCart() {
    return JSON.parse(sessionStorage.getItem(ORDER_CART));
  }
  public getProductCart() {
    return JSON.parse(sessionStorage.getItem(PRODUCT_CART));
  }

  clearOrderCart() {
    // Assuming localStorage is used for storing the cart
    window.sessionStorage.removeItem(ORDER_CART);
  }
  
  clearProductCarts() {
    window.sessionStorage.removeItem(PRODUCT_CART);
  }
  


  // Current City
public saveCurrentCity(currentCity: string){

  window.sessionStorage.removeItem(CURRENT_CITY);
  if (currentCity !== null || currentCity !== undefined) {
    window.sessionStorage.setItem(
      CURRENT_CITY,currentCity
    )
  } else {
    window.sessionStorage.setItem(CURRENT_CITY, null);
  }
}
public saveServiceId(businessServiceId: string) {
  // Logger.log(`User ID Inside Token Stoarge` + propertyId);
  window.sessionStorage.removeItem(SERVICE_ID);
  if (businessServiceId != null) {
    window.sessionStorage.setItem(SERVICE_ID, businessServiceId.toString());
  } else {
    window.sessionStorage.setItem(SERVICE_ID, null);
  }
}
public getServiceId(): string {
  return sessionStorage.getItem(SERVICE_ID);
}

public getCurrentCity(): string {
  return sessionStorage.getItem(CURRENT_CITY);
}
}
