<div class="success-container">
  <img
   width="100px" height="100px" style="border-radius: 8px"
  src="assets/images/successimage.png">
  <h2 style="margin-top: 20px;">Your order has been successfully submitted!</h2>
  <p>Thank you for visiting. Your order number is: {{ orderId }}</p>

  <div style="display: flex; justify-content: center; gap:10px">
    <button class="back-button" (click)="goBack()">Go Back</button>
    <button class="back-button" (click)="goBack()">Add New Order</button>
  </div>
 
</div>
