<div id="wrapper" (scroll)="onScrollEvent($event)" [ngClass]="{'header-top': isListingPages()}">

   <!--Header Frontend Panel -->
   <app-header *ngIf="isHeader"></app-header>

   <!-- Main Container -->
   <router-outlet></router-outlet>

   <!-- Footer Frontend Panel -->
   <app-footer *ngIf="isFooter"></app-footer>

   <!-- Back To Top Button -->
   <div id="backtotop"><a href="#"></a></div>

</div>



