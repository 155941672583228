import { ProductGroup } from './productGroup';
import { productVariationDtoList } from './productVariation';
export class Product {

  id: number;
  name: string;
  productCode: string;
  shortDescription: string;
  description: string;
  notes: string;
  sellUnitPrice: number;
  extraProductPrice: number;
  productGroupId: number;
  toppingProductGroupId:number;
  addOnProductGroup: ProductGroup;
  extraProductGroupDto: ProductGroup;
  extraProductGroupId:number;
  extraProductListDto:any[];
  buyUnitPrice: number;
  unitsInStock: number;
  unitsInOrder: number;
  discountedPrice: number;
  imageList: [
    {
      id: number;
      name: string;
      description: string;
      mainImage: boolean;
      url: string;
    }
  ];
  productVariationDtoList: productVariationDtoList[];

  totalPrice: number;
  quantityProduct: number;

  constructor() {}
}
