import { AgmCoreModule } from '@agm/core';
import { DatePipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { DropzoneConfigInterface, DropzoneModule, DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutes } from './app.routing';
import { AdminMenuItems } from './core/AdminHeader/admin-menu-items';
import { AdminHeaderComponent } from './core/AdminHeader/AdminHeader.component';
import { AdminSidebarComponent } from './core/AdminSidebar/AdminSidebar.component';
import { AppInstallComponent } from './core/AppInstall/AppInstall.component';
import { FooterComponent } from './core/Footer/Footer.component';
import { HeaderComponent } from './core/Header/Header.component';
import { MenuItems } from './core/Menu/menu-items';
import { MenuComponent } from './core/Menu/Menu.component';
import { SignInComponent } from './core/SignInPopup/SignIn.component';
import { AdminPanelLayoutComponent } from './layouts/adminPanel/AdminPanelLayout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { FrontendPanelLayoutComponent } from './layouts/frontendPanel/FrontendPanel.component';
import { TokenStorage } from './token.storage';
import {NgcCookieConsentModule, NgcCookieConsentConfig} from 'ngx-cookieconsent';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CartProductComponent } from './listing/shopping-cart/cart-product.component';
import { CartRComponent } from './listing/shopping-cart/cart-r.component';
import { CartReservationComponent } from './listing/shopping-cart/cart-reservation.component';
import { CartComponent } from './listing/shopping-cart/cart.component';
import { ProductListComponent } from './listing/shopping-cart/product-list.component';
import { ProductComponent } from './listing/shopping-cart/product.component';
import { ToastsContainer } from './toast-global/toasts-container.component';
import { OrderMenuLayoutComponent } from './listing/order-menu-layout/order-menu-layout.component';



const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  // Change this to your upload POST address:
  url: 'https://httpbin.org/post',
  maxFilesize: 50,
  acceptedFiles: 'image/*'
};
const cookieConfig:NgcCookieConsentConfig = {
  cookie: {
    domain: environment.appUrl // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  
    position: "bottom-left",
    theme: "classic",
    palette: {
      popup: {
        background: "#E5E5E4",
        text: "#221950",
        link: "#221950"
      },
      button: {
        background: "#57BFA1",
        text: "#fff",
        border: "transparent"
      }
    },
    type: "opt-out",
    content: {
      message: "This website uses cookies to ensure you get the best experience on our website.",
      dismiss: "Got it!",
      deny: "Refuse cookies",
      link: "Learn more",
      href: environment.appUrl,
      policy: "Cookie Policy"
    }
  
};

@NgModule({
  declarations: [
    AppComponent,
    AdminPanelLayoutComponent,
    FrontendPanelLayoutComponent,
    AuthLayoutComponent,
    HeaderComponent,
    FooterComponent,
    MenuComponent,
    SignInComponent,
    AdminHeaderComponent,
    AdminSidebarComponent,
    AppInstallComponent, ToastsContainer
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    DropzoneModule,
    Ng2TelInputModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(AppRoutes, { scrollPositionRestoration: 'enabled' }),
    HttpClientModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    AgmCoreModule.forRoot({
      apiKey: environment.googleKey
    })
  ],
  providers: [
    MenuItems,
    AdminMenuItems,
    TokenStorage,
    DatePipe,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: DROPZONE_CONFIG,
      useValue: DEFAULT_DROPZONE_CONFIG
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
